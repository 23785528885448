<template>
    <v-container fluid fill-height>
        <v-flex xs12 md12 lg12 xl12 class="text-center">

            <br/><br/>
            <v-progress-circular
                :size="128"
                :width="7"
                color="secondary"
                :indeterminate="!isError"
            >
                <v-img
                    :src="config.logo"
                    height="96"
                    width="96"
                    contain
                />
            </v-progress-circular>
            <h1 class="headline mt-5" v-if="!isError">
                {{ message.message }}
            </h1>
            <h1 class="headline mt-5 red--text text-center" v-else>
                {{ message.message }}<br>
            </h1>
        </v-flex>
    </v-container>
</template>
<script>
    import config from '@/config';
    import {mapState} from "vuex";
    export default {
        name: 'Loading',
        props: {
            message: {
                type: Object,
                required: true,
                validator: function (value) {
                    if (typeof value.status === 'undefined' || typeof value.message === 'undefined') {
                        return  false
                    }
                    return ['INFO', 'ERROR'].indexOf(value.status) !== -1;
                }
            }
        },
        components: {},
        computed: {
            isError() {
                return this.message.status === 'ERROR';
            },
            ...mapState('brand', ['branding']),
        },
        data: () => ({
          config: config
        }),
        methods: {},
        mounted() {}
    };
</script>
