import {adminService} from '@/services';

const state = {
  rateLimit: {
    accountsCleared: null
  }, loading: true, isAdmin: false, users: false, usersLoading: false, usersCount: 0
};

const actions = {
  clearRateLimitByUsername({dispatch, commit}, username) {
    commit('loading')
    adminService.clearRateLimitByUsername(username)
      .then(data => {
        if (data.data) {
          dispatch('alert/success', {message: 'Cleared one account'}, {root: true});
        } else {
          dispatch('alert/warning', {message: 'Username not in rate limit cache'}, {root: true});
        }
        commit('loaded')
      }, error => {
        dispatch('alert/error', {message: 'Could not clear rate limit by username'}, {root: true, error: error});
        commit('loaded')
      });
  }, clearRateLimits({dispatch, commit}) {
    commit('loading')
    adminService.clearRateLimits()
      .then(data => {
        if (data.data > 0) {
          dispatch('alert/success', {message: 'Successfully cleared ' + data.data + ' records'}, {root: true});
        } else {
          dispatch('alert/warning', {message: 'No records found in rate limit cache'}, {root: true});
        }
        commit('loaded')
      }, error => {
        dispatch('alert/error', {message: 'Could not clear rate limits'}, {root: true, error: error});
        commit('loaded')
      })
  }, isAdmin({dispatch, commit}) {
    commit('loading')
    adminService.isAdmin()
      .then(data => {
        commit('isAdmin', data.data);
        commit('loaded')
      }, error => {
        dispatch('alert/error', {message: 'Could not verify admin status'}, {root: true, error: error});
        commit('loaded')
      })
  }, getUserList({dispatch, commit}, {sortBy, sortDesc, page, itemsPerPage, search}) {
    commit('usersLoading')
    adminService.getUserList(sortBy, sortDesc, page, itemsPerPage, search)
      .then(data => {
        commit('getUserList', data.data);
        commit('usersLoaded')
      }, error => {
        dispatch('alert/error', {message: 'Could not fetch user list'}, {root: true, error: error});
        commit('usersLoaded')
      })
  },
};

const mutations = {
  isAdmin(state, isAdmin) {
    state.isAdmin = isAdmin;
    state.loading = false;
  }, loading(state) {
    state.loading = true
  }, loaded(state) {
    state.loading = false
  }, getUserList(state, data) {
    state.users = data.users;
    state.usersCount = data.total;
  }, usersLoading(state) {
    state.usersLoading = true
    state.users = []
  }, usersLoaded(state) {
    state.usersLoading = false
  }
};

export const admin = {
  namespaced: true, state, actions, mutations
};
