const state = {
    type: null,
    message: null,
    timeout: 6000
};

const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    warning({ commit }, message) {
      commit('warning', message);
    },
    clear({ commit }, message) {
        commit('success', message);
    }
};

const mutations = {
    success(state, message) {
        if (typeof message === 'undefined') {
            return;
        }
        state.type = 'green';
        state.message = message;
        state.timeout = calculateTimeout(message.message)
    },
    error(state, message) {
        if (typeof message === 'undefined') {
            return;
        }
        state.type = 'red';
        state.message = message;
        state.timeout = calculateTimeout(message.message)
    },
    warning(state, message) {
      if (typeof message === 'undefined') {
        return;
      }
      state.type = 'warning';
      state.message = message;
      state.timeout = calculateTimeout(message.message)
    },
    clear(state) {
        state.type = null;
        state.message = null;
    }
};

function calculateTimeout(message)
{
    let defaultTimeout = 6000;
    if (message.length > 100) {
        return defaultTimeout + ((message.length - 100) / 60) * 1000
    }
    return defaultTimeout
}

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};
