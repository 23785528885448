<template>
  <v-container>
    <vue-recaptcha
        v-if="config.recaptchaEnabled"
        ref="invisibleRecaptcha"
        @verify="handlePatientIdentityRequest"
        @error="showError"
        @expired="onExpired"
        size="invisible"
        :sitekey="recaptchaSiteKey()">
    </vue-recaptcha>

    <div v-if="loading" class="text-center">
      <v-progress-circular color="primary" size="48" indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <h1 class="headline font-weight-light mb-5">{{ $_t(formHeadline)}}</h1>
      <v-form @submit.prevent="handleSubmit('patientIdentityForm')" data-vv-scope="patientIdentityForm">
        <div v-for="inputField in patientFormFields" v-bind:key="inputField">
          <DateOfBirthInput
              v-if="inputField === 'date_of_birth'"
              v-model="formData[inputField]"
              :placeholder="!autofilled ? ' ' : ''"
              :submitted="formLoading"
              v-validate="'required'"
              :data-vv-name="inputField"
              :error-messages="$_t(vErrors.collect('patientIdentityForm.' + inputField))"
          ></DateOfBirthInput>
          <MobileNumberInput
              v-else-if="inputField === 'phone_number'"
              v-model="formData[inputField]"
              label="Mobile number"
              :submitted="formLoading"
              :mobile-valid="mobileValid"
              :on-blur-mobile="onBlurMobile"
              :on-validate-mobile="onValidateMobile"
          ></MobileNumberInput>
          <TextInput
              v-else-if="inputField === 'name'"
              v-model="formData[inputField]"
              :placeholder="!autofilled ? ' ' : ''"
              :submitted="formLoading"
              label="Last name"
              icon="mdi-face-man"
              :error-messages="$_t(vErrors.collect('patientIdentityForm.' + inputField))"
              v-validate="'required|min:1|max:40'"
              :data-vv-name="inputField"
          ></TextInput>
          <TextInput
              v-else-if="inputField === 'email'"
              v-model="formData[inputField]"
              :placeholder="!autofilled ? ' ' : ''"
              :submitted="formLoading"
              label="Email"
              icon="mdi-email"
              :error-messages="$_t(vErrors.collect('patientIdentityForm.' + inputField))"
              v-validate="'email|min:4|max:100'"
              :data-vv-name="inputField"
          ></TextInput>
          <TextInput
              v-else-if="inputField === 'username'"
              v-model="formData[inputField]"
              :placeholder="!autofilled ? ' ' : ''"
              :submitted="formLoading"
              label="Username"
              icon="mdi-face-man"
              :error-messages="$_t(vErrors.collect('patientIdentityForm.' + inputField))"
              v-validate="'min:4|max:100'"
              :data-vv-name="inputField"
          ></TextInput>
          <TextInput
              v-else-if="inputField === 'password'"
              v-model="formData[inputField]"
              :placeholder="!autofilled ? ' ' : ''"
              :submitted="formLoading"
              label="Password"
              icon="mdi-lock"
              :password="true"
              :error-messages="$_t(vErrors.collect('patientIdentityForm.' + inputField))"
              v-validate="'min:4|max:100'"
              :data-vv-name="inputField"
          ></TextInput>
          <OTPInput
            v-else-if="inputField === 'username_last_5'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="formLoading"
            label="username_last_5"
            icon="mdi-lock"
            :password="true"
            :error-messages="$_t(vErrors.collect('patientIdentityForm.' + inputField))"
            :data-vv-name="inputField"
            :length="5"
          ></OTPInput>
          <OTPInput
            v-else-if="inputField === 'mobile_last_6'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="formLoading"
            label="mobile_last_6"
            icon="mdi-lock"
            :password="true"
            :error-messages="$_t(vErrors.collect('patientIdentityForm.' + inputField))"
            :data-vv-name="inputField"
            :length="6"
          ></OTPInput>
        </div>

        <p class="text-center mt-3 body-2" v-if="identifyPatientByEmail">{{ $_t('or')}}</p>
        <v-text-field
            v-validate="'email|min:4|max:100'"
            :error-messages="$_t(vErrors.collect('patientIdentityForm.email'))"
            data-vv-name="email"
            append-icon="mdi-email"
            v-model="formData['email']"
            name="email"
            :label="$_t('E-mail')"
            color="primary"
            outlined
            autocomplete="off"
            :placeholder="!autofilled ? ' ' : ''"
            :disabled="formLoading"
            v-if="identifyPatientByEmail"
        ></v-text-field>

        <v-layout align-center pa-0 ma-0 my-5>
          <router-link :to="'reset-password'" tabindex="4" v-if="config.passwordResetEnabled">
            <a style="text-decoration: underline">{{ $_t('Forgot your password?') }}</a>
          </router-link>
          <router-link :to="'create-password'" tabindex="4" v-if="config.passwordCreateEnabled">
            <a style="text-decoration: underline">{{ $_t('Set up password') }}</a>
          </router-link>
          <v-spacer></v-spacer>
          <v-btn
              type="submit"
              large
              class="ma-0"
              :disabled="formLoading"
              color="primary"
              light>
            <div v-if="!formLoading">
              {{ $_t('Next')}}
            </div>
            <div v-else>
              <div class="loader"></div>
            </div>
          </v-btn>
        </v-layout>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import config from '@/config'
import VueRecaptcha from "vue-recaptcha";
import DateOfBirthInput from "@/components/Login/Patient/FormInputs/DateOfBirthInput";
import TextInput from "@/components/Login/Patient/FormInputs/TextInput";
import MobileNumberInput from "@/components/Login/Patient/FormInputs/MobileNumberInput";
import OTPInput from "@/components/Login/Patient/FormInputs/OTPInput";

export default {
  components: {
    VueRecaptcha,
    DateOfBirthInput,
    TextInput,
    MobileNumberInput,
    OTPInput
  },
  data () {
    return {
      mobileValid: true,
      mobileValidTemp: null,
      autofilled: false,
      config: config,
      formData: [],
      patientFormFields: config.patientFormFields,
      loading: true,
      formHeadline: 'Your details'
    }
  },
  computed: {
    ...mapState('account', ['status', 'patient', 'formLoading']),
    identifyPatientByEmail() {
      if (this.$route.query.username && this.config.patientLoginByUsernameEnabled) {
        return false
      } else {
        return this.config.identifyPatientByEmail
      }
    }
  },
  created () {
    if (this.$route.query.username && this.config.patientLoginByUsernameEnabled) {
      this.formData['username'] = this.$route.query.username
      this.patientFormFields = this.config.patientLoginByUsernameFormFields
      this.formHeadline = this.config.patientLoginByUsernameFormHeadline
    }
    this.loading = false
  },
  methods: {
    ...mapActions({
      submitPatientIdentity: 'account/submitPatientIdentity',
      alertError: 'alert/error',
    }),
    handleSubmit(scope) {
      this.$validator.validateAll(scope)
          .then((valid) => {
            Object.entries(this.formData).forEach((v) => {
              if(!String(v[1]).trim()) {
                this.formData[v[0]] = ''; // Reset input cursor
                delete this.formData[v[0]]
              }
            })

            if (!this.formData['phone_number'] && !this.formData['email'] && this.identifyPatientByEmail) {
              this.alertError({
                message: this.$_t('Phone number or E-mail is required')
              });
              valid = false;
            } else if (!this.formData['phone_number'] && !this.identifyPatientByEmail && this.patientFormFields.includes('phone_number')) {
              this.alertError({
                message: this.$_t('Phone number is required')
              });
              valid = false;
            }

            if (this.formData['phone_number'] && !this.mobileValid) {
              this.alertError({
                message: this.$_t('Phone number is not valid')
              });
              valid = false;
            }

            if (valid) {
              if (config.recaptchaEnabled) {
                this.$refs.invisibleRecaptcha.execute();
              } else {
                this.handlePatientIdentityRequest('1');
              }
            }
          });
    },
    onValidateMobile({ isValid }) {
      if (this.mobileValidTemp !== null) {
        this.mobileValid = isValid;
      }
      this.mobileValidTemp = isValid;
    },
    onBlurMobile() {
      this.mobileValid = this.mobileValidTemp;
    },
    handlePatientIdentityRequest(token) {
      this.formData['recaptcha_token'] = token

      this.submitPatientIdentity(Object.assign({}, this.formData)).then(() => {
        if (config.recaptchaEnabled) {
          this.resetRecaptcha();
        }
      });
    },
    showError(message) {
      this.alertError(message)
    },
    onExpired() {
      window.location.reload(true)
    },
    resetRecaptcha() {
      this.recaptchaToken = ''
      if (this.$refs.invisibleRecaptcha) {
        this.$refs.invisibleRecaptcha.reset()
      }
    },
    recaptchaSiteKey() {
      return config.recaptchaSiteKey;
    }
  },
  mounted: function () {
  },
  destroyed() {
  },
  watch: {
    email() {
      this.autofilled = true;
    }
  }
}
</script>
