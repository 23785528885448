<template>
  <v-text-field
      :append-icon="icon"
      @input="updateValue"
      :label="$_t(label)"
      color="primary"
      light
      outlined
      :placeholder="placeholder"
      :disabled="submitted"
      :error-messages="errorMessages"
      :type="(password) ? 'password' : 'text'"
      :value="value"
      autocomplete="off"
      :readonly="readonly"
      @focus="readonly = false"
      @blur="readonly = true"
      ontouchstart="this.removeAttribute('readonly');"
      :autofocus="autofocus"
  ></v-text-field>
</template>

<script>

export default {
  props: {
    submitted: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    label: {
      type: String
    },
    icon: {
      type: String
    },
    errorMessages: {},
    password: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      readonly: true
    }
  },

  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  },
}
</script>
