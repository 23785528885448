<template>
  <v-dialog
    ref="dialog"
    v-model="birthdayModal"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="$_t('Date of birth')"
        append-icon="mdi-cake"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        light
        :placeholder="placeholder"
        :disabled="submitted"
        :value="formattedDate"
        @input="updateValue"
        :error-messages="errorMessages"
        :autofocus="autofocus"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      scrollable
      :max="new Date().toISOString().substr(0, 10)"
      min="1901-01-01"
      @input="updateValue"
      :disabled="submitted"
      :locale="config.bcp47LanguageTag"
      :value="date"
    ></v-date-picker>
  </v-dialog>
</template>

<script>

import config from '@/config'
import moment from 'moment'

export default {
  props: {
    submitted: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    errorMessages: {
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      config: config,
      birthdayModal: null
    }
  },

  methods: {
    updateValue: function (value) {
      this.birthdayModal = false
      this.$emit('input', value)
    }
  },
  computed: {
    formattedDate() {
      return this.value
        ? moment(this.value).format(config.dateFormat)
        : "";
    },
    date() {
      return this.value
    }
  },
  watch: {
    birthdayModal (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  }
}
</script>
