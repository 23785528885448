<template>
  <v-container fluid fill-height pa-0 ma-0 :style="style" v-show="!status.loading">
    <v-layout row wrap pa-0>
      <v-flex xs12 sm8 md5 lg3 style="margin: 0 auto;min-width: 400px">
        <v-container fluid fill-height>
          <v-card class="elevation-12 py-5 px-4" color="rgba(255,255,255,0.8)" style="width:100%">
            <p class="text-center">
              <img :src="(branding.use_logo_url) ? branding.brand_logo_url : branding.brand_logo_base64" style="max-width:200px; max-height: 100px;" />
            </p>
            <!-- Login form -->
            <div class="form-wrapper">
              <UserLoginForm v-if="!twoFactor.enabled"/>
              <UserTwoFactorForm v-if="twoFactor.enabled && config.twoFactorEnabled && !twoFactor.completed"></UserTwoFactorForm>
              <UserTermsOfServiceForm v-if="config.checkTosEnabled && twoFactor.completed && !isTosAccepted"></UserTermsOfServiceForm>
            </div>
            <div v-if="config.privacyLink || config.termsLink" style="float:right">
              <a v-ripple class="primary--text v-btn v-btn--text v-size--x-small mb-2 pa-4" style="white-space: normal; text-align: right; float:right;" v-if="config.privacyLink" :href="config.privacyLink" target="_blank">
                {{ $_t('privacy_user') }}
              </a>
              <a v-ripple class="primary--text v-btn v-btn--text v-size--x-small mb-2 pa-4" style="white-space: normal; text-align: right; float:right;" v-if="config.termsLink" :href="config.termsLink" target="_blank">
                {{ $_t('terms_user') }}
              </a>
            </div>
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import config from '@/config';
import UserLoginForm from "@/components/Login/User/UserLoginForm";
import UserTwoFactorForm from "@/components/Login/User/UserTwoFactorForm";
import UserTermsOfServiceForm from "@/components/Login/User/UserTermsOfServiceForm";

export default {
  components: {
    UserLoginForm,
    UserTwoFactorForm,
    UserTermsOfServiceForm
  },
  data () {
    return {
      config: {'logoFull': '#', 'background': false}
    }
  },
  computed: {
    ...mapState('account', ['status', 'user', 'redirectLink', 'isTosAccepted', 'twoFactor']),
    ...mapState('brand', ['branding']),
    style() {
      if (this.config.background) {
        return {
          'background-size': 'cover',
          'background-image': 'url("' + this.config.background + '")'
        }
      }
      return ''
    }
  },
  created () {
    if(this.status.loggedIn) {
      this.$router.push({ path: '/' });
    }
  },
  methods: {
    ...mapActions('brand', ['getBranding']),
    ...mapActions('account', ['loading'])
  },
  mounted: function () {
    this.config = config
  },
  destroyed() {}
}
</script>
