import config from "@/config";

export const userService = {
    isLoggedIn,
    verifyMobileNumber,
    logout,
    register,
    passwordResetRequest,
    verifyResetPasswordTokenRequest,
    changePasswordRequest,
    socialLogin,
    isGoogleLoginEnabled,
    isRegistrationEnabled,
    submitPatientIdentity,
    submitUserIdentity,
    loginPatient,
    sendPatientTwoFactorCode,
    verifyPatientTwoFactorCode,
    sendUserTwoFactorCode,
    verifyUserTwoFactorCode,
    isRecaptchaIPWhitelisted,
    getAuthRequestParams,
    patientPasswordResetRequest,
    passwordCreateRequest,
    verifyCreatePasswordRequest,
    acceptTos
};

function verifyUserTwoFactorCode(usernameOrEmail, recaptchaToken, method, code)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usernameOrEmail, recaptchaToken, method, code })
    };

    return fetch(config.authApiUrl + `/auth/verify_user_tf_code`, requestOptions)
        .then(handleResponse);
}

function sendUserTwoFactorCode(usernameOrEmail, recaptchaToken, method)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usernameOrEmail, recaptchaToken, method })
    };

    return fetch(config.authApiUrl + `/auth/send_user_tf_code`, requestOptions)
        .then(handleResponse);
}

function verifyPatientTwoFactorCode(formData)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return fetch(config.authApiUrl + `/auth/verify_patient_tf_code`, requestOptions)
        .then(handleResponse);
}

function sendPatientTwoFactorCode(formData)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return fetch(config.authApiUrl + `/auth/send_patient_tf_code`, requestOptions)
        .then(handleResponse);
}

function submitPatientIdentity(formData)
{
    const authRequestParams = getAuthRequestParams()
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return fetch(config.authApiUrl + `/auth/verify_patient_identity` + ((authRequestParams) ? authRequestParams : ''), requestOptions)
        .then(handleResponse);
}

function submitUserIdentity(usernameOrEmail, password, recaptchaToken)
{
    const authRequestParams = getAuthRequestParams()
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usernameOrEmail, password, recaptchaToken })
    };

    return fetch(config.authApiUrl + `/auth/verify_user_identity` + ((authRequestParams) ? authRequestParams : ''), requestOptions)
        .then(handleResponse);
}

function loginPatient(dateOfBirth, lastName, phoneNumber, email, recaptchaToken, password)
{
    const authRequestParams = getAuthRequestParams()
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ dateOfBirth, lastName, phoneNumber, email, recaptchaToken, password })
    };

    return fetch(config.authApiUrl + `/auth/login_patient` + ((authRequestParams) ? authRequestParams : ''), requestOptions)
        .then(handleResponse);
}

function getAuthRequestParams()
{
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    if (params.get('client_id') && params.get('response_type') && params.get('state')) {
        return queryString
    }
    return false
}

function socialLogin(type, id_token, recaptchaToken, link)
{
    const authRequestParams = getAuthRequestParams()
    let body = { type, id_token, recaptchaToken }
    if (link) {
      body.link = true
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(config.authApiUrl + `/auth/social_login` + ((authRequestParams) ? authRequestParams : ''), requestOptions)
        .then(handleResponse);
}

function isLoggedIn()
{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(config.authApiUrl + `/auth/is_logged_in`, requestOptions)
        .then(handleResponse);
}

function logout(callback)
{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(config.authApiUrl + `/auth/logout`, requestOptions)
        .then(handleResponse).then(callback);
}

function verifyMobileNumber(code, email, password)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email, password: password, code: code })
    };

    return fetch(config.authApiUrl+`/auth/verify/mobile`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function register(user)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(config.authApiUrl + `/auth/register`, requestOptions).then(handleResponse);
}

function passwordResetRequest(email)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
    };

    return fetch(config.authApiUrl + `/auth/password/reset`, requestOptions).then(handleResponse);
}

function patientPasswordResetRequest(formData)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return fetch(config.authApiUrl + `/auth/password/reset/patient`, requestOptions).then(handleResponse);
}

function verifyResetPasswordTokenRequest(token)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: token })
    };

    return fetch(config.authApiUrl + `/auth/password/reset/verify_token`, requestOptions).then(handleResponse);
}

function changePasswordRequest(password, token)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password: password, token: token })
    };

    return fetch(config.authApiUrl + `/auth/password/change`, requestOptions).then(handleResponse);
}

function isGoogleLoginEnabled()
{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(config.authApiUrl + `/auth/social/google/enabled`, requestOptions)
        .then(handleResponse);
}

function isRegistrationEnabled()
{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(config.authApiUrl + `/auth/registration/enabled`, requestOptions)
        .then(handleResponse);
}

function isRecaptchaIPWhitelisted()
{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(config.authApiUrl + `/auth/recaptcha_required`, requestOptions)
        .then(handleResponse);
}

function passwordCreateRequest(formData)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return fetch(config.authApiUrl + `/auth/password/create`, requestOptions).then(handleResponse);
}

function verifyCreatePasswordRequest(formData)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return fetch(config.authApiUrl + `/auth/password/create/verify`, requestOptions)
        .then(handleResponse);
}

function acceptTos(formData)
{
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(formData)
  };

  return fetch(config.authApiUrl + `/auth/tos/accept`, requestOptions).then(handleResponse);
}

function handleResponse(response)
{
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout().then(() => {
                    location.reload();
                });
            }

            const error = (data && data.error) || response.statusText;

            return Promise.reject(error);
        }

        return data;
    });
}
