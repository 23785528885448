import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import Approve from '@/views/Approve.vue';
import {account} from '@/store/account.module';
import config from '@/config';
import PatientLogin from '@/views/Patient/Login';
import UserLogin from '@/views/User/Login';
import PatientResetPassword from '@/views/Patient/ResetPassword';
import PatientCreatePassword from '@/views/Patient/CreatePassword';
import UserResetPassword from '@/views/User/ResetPassword';
import {userService} from "@/services";
import AdminHome from '@/views/Admin/Home.vue';
import AdminLogin from '@/views/Admin/Login.vue';

Vue.use(Router);

let commonRoutes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/approve-prompt',
    name: 'approve',
    component: Approve
  },
  {
    path: '/admin',
    name: 'admin login',
    component: AdminLogin
  },
  {
    path: '/admin/home',
    name: 'admin home',
    component: AdminHome
  },
];

let patientRoutes = [
  {
    path: '/login',
    name: 'login',
    component: PatientLogin
  }
];

if (config.passwordResetEnabled) {
  patientRoutes.push({
    path: '/reset-password',
    name: 'reset-password',
    component: PatientResetPassword
  });
}

if (config.passwordCreateEnabled) {
  patientRoutes.push({
    path: '/create-password',
    name: 'create-password',
    component: PatientCreatePassword
  });
}

let userRoutes = [
  {
    path: '/login',
    name: 'login',
    component: UserLogin
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: UserResetPassword
  }
];

let routes = commonRoutes;
if (config.appType === 'user' || config.appType === 'referrer') {
  routes = routes.concat(userRoutes);
} else if (config.appType === 'patient') {
  routes = routes.concat(patientRoutes);
} else {
  throw new Error('Configured `appType` is unrecognized (' + config.appType + ')');
}

routes = routes.concat([
  {path: '*', redirect: '/login'}
]);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/reset-password', '/create-password', '/admin'];
  const authRequired = !publicPages.includes(to.path);
  const defaultAppRedirectPages = ['/login', '/'];
  const isDefaultAppRedirectPage = defaultAppRedirectPages.includes(to.path);

  if (config.defaultAppRedirectEnabled && !userService.getAuthRequestParams() && isDefaultAppRedirectPage) {
    window.location.href = config.defaultAppRedirectUrl
  }

  if (authRequired) {
    account.actions.loggedInRedirect(next);
  } else {
    next();
  }
});

export default router;
