import config from '@/config';

export const brandService = {
  getBranding
};

function getBranding(code) {
  const requestOptions = {
    method: 'GET',
    headers: {'Content-Type': 'application/json'}
  };

  return fetch(config.authApiUrl + `/auth/brand/` + code, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        location.reload();
        return;
      }

      const error = (data && data.error) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}
