const device = {
  generateDeviceCookie(username, cookieValue, expiryTimestamp) {
    let cookieName = this.hashUsername(username)
    const date = new Date((expiryTimestamp * 1000) - 5 * 60 * 1000); //expiry - 5 minutes
    let expires = "expires="+ date.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
  },
  isCookieSetForUsername(username) {
    let cookieName = this.hashUsername(username)
    return this.getCookie(cookieName)
  },
  hashUsername(username) {
    let arr = username.split('');
    let hash = arr.reduce(
      (hashCode, currentVal) =>
        (hashCode = currentVal.charCodeAt(0) + (hashCode << 6) + (hashCode << 16) - hashCode),
      0
    );
    return 'udf_' + hash;
  },
  getCookie(cookieName) {
    let name = cookieName + "=";
    let cookieArray = document.cookie.split(';');
    for(let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return false
  }
}

export default device;
