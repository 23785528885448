<template>
  <v-container>
    <div v-if="html">
      <div v-html="html"></div>
      <v-layout align-end justify-end>
        <v-spacer></v-spacer>
        <v-btn color="primary" class="font-weight-regular" @click="submit">{{$_t('I accept')}}</v-btn>
      </v-layout>
      </div>
    <div v-else class="text-center">
      <v-progress-circular
        :size="75"
        :width="7"
        color="secondary"
        indeterminate
      >
      </v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import config from "@/config";

export default {
  data () {
    return {
      config: config,
      tosModel: false,
      html: false
    }
  },
  computed: {
    ...mapState('account', ['loginFormObject'])
  },
  created () {
    if (!config.tosTemplate) {
      // eslint-disable-next-line
      console.error('ToS template not configured')
      return
    }

    fetch('/templates/tos/' + config.tosTemplate).then(res => {
      return res.text();
    }).then(html => {
      this.html = html
    })
  },
  methods: {
    ...mapActions('account', ['acceptTos', 'loading', 'loaded']),
    submit() {
      this.loading();
      this.acceptTos(this.loginFormObject)
    }
  },
  watch: {
  }
}
</script>
