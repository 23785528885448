import {brandService} from '@/services';
import config from '@/config';

const state = {
  branding: {
    brand_logo_url: config.logoFull,
    brand_logo_base64: null,
    use_logo_url: true,
    title: config.title
  }
};

const actions = {
  getBranding({commit}, {code, callback}) {
    brandService.getBranding(code)
      .then(
        data => {
          if (data.data && typeof data.data === 'object' && Object.keys(data.data).length) {
            commit('getBrandingSuccess', data.data);
          } else {
            commit('getBrandingError')
          }
          callback();
        },
        () => {
          commit('getBrandingError')
          callback();
        }
      );
  }
};

const mutations = {
  getBrandingSuccess(state, data) {
    state.branding = data;
  },
  getBrandingError(state) {
    state.branding = {
      brand_logo_url: config.logoFull,
      brand_logo_base64: null,
      use_logo_url: true,
      title: config.title
    }
  }
};

export const brand = {
  namespaced: true,
  state,
  actions,
  mutations
};
