<template>
  <v-container>
    <h1 class="headline font-weight-light mb-5" v-show="!status.hideForm">{{ $_t('Reset password') }}</h1>
    <div class="form-wrapper" v-show="!status.passwordTokenValid && !status.passwordResetSuccess">
      <v-form
        v-show="!status.hideForm"
        @submit.prevent="handleSubmit('reset-password-form')"
        data-vv-scope="reset-password-form"
        v-bind:style="{ padding: ($vuetify.breakpoint.xsOnly ? '1em' : 'none') }"
      >
        <v-text-field
          v-validate="'required|email|min:4|max:100'"
          :error-messages="vErrors.collect('reset-password-form.email')"
          data-vv-name="email"
          append-icon="mdi-account-outline"
          v-model="email"
          name="email"
          :label="$_t('E-mail')"
          color="primary"
          outlined
          autocomplete="none"
          autofocus
        ></v-text-field>

        <v-layout align-center pa-0 ma-0>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-btn
                type="submit"
                large
                class="ma-0 col-12 py-0"
                :disabled="submitted"
                color="primary">
                  <span v-if="!submitted">
                    {{ $_t('Reset password') }}
                  </span>
                <span v-else>
                    <div class="loader"></div>
                  </span>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <router-link :to="'login'" style="text-decoration: none">
                <v-btn
                  large
                  outlined
                  class="ma-0 col-12"
                  color="primary"
                >
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                  {{ $_t('Back to sign in') }}
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-layout>
      </v-form>
      <div v-show="status.hideForm">
        <v-row>
          <v-icon color="primary" large style="margin: 0 auto;">mdi-check-circle-outline</v-icon>
        </v-row>
        <v-row class="text-xs-center">
          <p class="text-center" style="width: 100%">
            {{ $_t('Check your inbox!') }}
          </p>
        </v-row>
      </div>
    </div>
    <div class="form-wrapper" v-show="status.passwordTokenValid || status.passwordResetSuccess">
      <v-form
        @submit.prevent="handleSubmit('change-password-form')"
        data-vv-scope="change-password-form"
        v-bind:style="{ padding: ($vuetify.breakpoint.xsOnly ? '1em' : 'none') }"
        v-show="!status.hideForm"
      >
        <v-text-field
          v-validate="{ required: true }"
          :error-messages="vErrors.collect('change-password-form.password')"
          data-vv-name="password"
          append-icon="mdi-lock"
          v-model="password"
          name="password"
          :label="$_t('Password')"
          color="primary"
          outlined
          autocomplete="none"
          ref="password"
          type="password"
        ></v-text-field>

        <v-text-field
          v-validate="{ required: true, confirmed: 'password' }"
          :error-messages="vErrors.collect('change-password-form.cpassword')"
          data-vv-name="cpassword"
          append-icon="mdi-lock"
          v-model="cpassword"
          name="cpassword"
          :label="$_t('Confirm password')"
          color="primary"
          outlined
          autocomplete="none"
          type="password"
        ></v-text-field>

        <v-layout align-center pa-0 ma-0>
          <router-link :to="'login'" style="text-decoration: none">
            <v-btn
              large
              outlined
              class="ma-0"
              color="primary"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
              {{ $_t('Back to sign in') }}
            </v-btn>
          </router-link>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            large
            :disabled="submitted"
            color="primary"
            class="ma-0">
            <div v-if="!submitted">
              {{ $_t('Save') }}
            </div>
            <div v-else>
              <div class="loader"></div>
            </div>
          </v-btn>
        </v-layout>
      </v-form>
      <div v-show="status.hideForm">
        <v-row>
          <v-icon color="primary" large style="margin: 0 auto;">mdi-check-circle-outline</v-icon>
        </v-row>
        <v-row class="text-xs-center">
          <p class="text-center" style="width: 100%">
            <br/>
            {{ $_t('Your password has been reset.') }}
            <span v-if="config.passwordResetRedirect">
                {{
                $_t("You will now be redirected to the application. Use the link below if you're not redirected in 5 seconds:")
              }}<br/>
                <a :href="config.passwordResetRedirect">{{ config.passwordResetRedirect }}</a>
              </span>
            <span v-else>
                {{ $_t('Visit the application again and use your newly created password to log in.') }}
              </span>
          </p>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import config from '@/config';
import {userService} from "@/services";

export default {
  data() {
    return {
      email: '',
      submitted: false,
      config: {'logoFull': '#'},
      password: '',
      cpassword: '',
      token: '',
      hideForm: false
    }
  },
  computed: {
    ...mapState('account', ['status'])
  },
  mounted() {
    this.loading()
    if (this.status.loggedIn) {
      userService.logout(this.$router.go());
      return;
    }
    this.resetPasswordTokenValidity()
    this.config = config;
    this.token = this.$route.query.token;
    if (this.token !== undefined && this.token !== '' && !this.status.hideForm) {
      this.verifyResetPasswordTokenRequest(this.token);
    } else {
      if (config.passwordResetEnabled) {
        this.loaded()
      } else {
        this.$router.push({path: '/'});
      }
    }
  },
  created() {
  },
  methods: {
    ...mapActions('account', [
      'sendPasswordResetRequest',
      'verifyResetPasswordTokenRequest',
      'changePasswordRequest',
      'loading',
      'loaded',
      'resetPasswordTokenValidity'
    ]),
    handleSubmit(scope) {
      this.submitted = true;
      this.$validator.validateAll(scope)
        .then((valid) => {
          if (valid) {
            if (scope === 'reset-password-form') {
              this.sendPasswordResetRequest(this.email);
            } else {
              this.changePasswordRequest({
                password: this.password, token: this.token, callback: (result) => {
                  if (!result) {
                    this.submitted = false;
                  }
                }
              });
            }
          } else {
            this.submitted = false;
          }
        });
    }
  }
}
</script>
