<template>
    <v-container fluid fill-height pa-0>
        <v-layout fill-height pa-0>
            <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly, 'pa-5': $vuetify.breakpoint.smAndUp}">
                <v-layout :column="$vuetify.breakpoint.smAndDown" :row="$vuetify.breakpoint.mdAndUp" wrap>
                    <v-flex xs12 md12 lg12 xl12 class="text-xs-center">
                        <v-card>
                            <div v-if="loading && clientInfo == null || submitted">
                                <v-progress-linear :indeterminate="true"></v-progress-linear>
                            </div>
                            <div v-if="!loading && clientInfo && !submitted" class="font-weight-thin" style="margin: 15px auto; font-size: 175%;text-align:center;">
                                <br/>
                                <img :src="clientInfo.image" :alt="clientInfo.name" width="75px" />
                                <br/>
                                The app <br>
                                <span class="text-capitalize font-weight-bold">{{clientInfo.name}}</span> <br/>
                                would like to access your:<br/><br/>
                                <!-- todo: scopes -->
                                <span>&#9900;&nbsp;Basic information</span>
                                <br/><br/>
                                Allow access?
                                <br/><br/>
                                <v-btn outline large class="mr-0" color="danger" v-on:click="submitApproval('no')">
                                    Deny
                                    <v-icon dark right>mdi-block</v-icon>
                                </v-btn>
                                <v-btn outline large class="mr-0" color="success" v-on:click="submitApproval('yes')">
                                    Allow
                                    <v-icon dark right>mdi-check_circle</v-icon>
                                </v-btn>
                            </div>
                            <div v-if="error" class="font-weight-thin" style="margin: 15px auto; font-size: 175%;text-align:center;">
                                No client provided for approval.
                            </div>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-layout>
    </v-container>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        data: function() {
            return {
                notFound: false,
                clientId: null,
                submitted: false
            }
        },
        methods: {
            ...mapActions('client', ['getClient', 'postApproval']),
            submitApproval(answer) {
                let that = this;
                that.submitted = true;
                that.postApproval({answer}).then(function() {
                    if(answer == 'no') {
                        //chrome & ie
                        window.open('','_self').close();
                        //ff
                        window.close();
                    }
                });
            }
        },
        computed: {
            ...mapState('client', ['clientInfo', 'loading', 'error'])
        },
        watch: {

        },
        created() {

        },
        mounted() {
            let that = this;

            that.clientId = that.$route.query.c;
            if(that.clientId !== null) {
                that.getClient({id: that.clientId});
            } else {
                that.$router.push({ path: '/home' });
            }
        }
    }
</script>
