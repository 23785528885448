import {clientService} from '@/services';
import router from "@/helpers/router";

const state = { clientInfo: null, loading: true, error: false };

const actions = {
    getClient({dispatch, commit}, { id }) {

        clientService.getClient(id)
            .then(
                data => {
                    commit('getClientSuccess', data.data);
                },
                error => {
                    commit('getClientError', error);
                    dispatch('alert/error', {message: 'Couldn\'t fetch the client'}, { root: true });
                }
            );
    },
    postApproval({dispatch}, { answer }) {

        clientService.postApproval(answer)
            .then(
                data => {
                    window.location.href = data.data;
                },
                () => {
                    dispatch('alert/error', {message: 'Couldn\'t complete client approval'}, { root: true });
                    router.push({ name: 'home' });
                }
            )
    }
};

const mutations = {
    getClientSuccess(state, data) {
        state.clientInfo = data;
        state.loading = false;
    },
    getClientError(state) {
        state.clientInfo = false;
        state.loading = false;
        state.error = true;
    }
};

export const client = {
    namespaced: true,
    state,
    actions,
    mutations
};
