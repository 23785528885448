<template>
  <v-container>
    <div style="margin:0 auto">
    <vue-recaptcha
      v-if="config.recaptchaProvider === 'google'"
      ref="invisibleRecaptcha"
      @verify="handleLogin"
      @error="showError"
      @expired="onExpired"
      size="invisible"
      :sitekey="recaptchaSiteKey()">
    </vue-recaptcha>
    <div
      v-if="config.recaptchaProvider === 'cloudflare'"
      style="text-align: center"
      class="pb-4"
      id="turnstile-container"
    >
    </div>
    </div>
  </v-container>
</template>
<script>
import config from '@/config';
import {mapActions, mapState} from "vuex";
import VueRecaptcha from "vue-recaptcha";
export default {
  name: 'Captcha',
  props: {
  },
  components: {VueRecaptcha},
  computed: {
    isError() {
      return this.message.status === 'ERROR';
    },
    recaptchaSiteKey() {
      return config.recaptchaSiteKey;
    },
    ...mapState('brand', ['branding']),
  },
  data: () => ({
    config: config,
    turnstileToken: ''
  }),
  methods: {
    resetRecaptcha() {
      if (this.$refs.invisibleRecaptcha) {
        this.$refs.invisibleRecaptcha.reset()
      }
    },
    handleLogin(token) {
      this.$emit('handleLogin', token)
    },
    ...mapActions('account', ['isRecaptchaIPWhitelisted']),
    ...mapActions('alert', ['error']),
    showError(message) {
      this.error(message)
    },
    onExpired() {
      window.location.reload(true)
    },
    verify() {
      if (config.recaptchaProvider === 'google') {
        this.$refs.invisibleRecaptcha.execute();
      } else if (config.recaptchaProvider === 'cloudflare') {
        if (!this.turnstileToken) {
          this.showError({message: 'Complete captcha'})
          return
        }
        this.handleLogin(this.turnstileToken)
      }
    }
  },
  created() {
    this.isRecaptchaIPWhitelisted();
  },
  mounted() {
    if (config.recaptchaProvider === 'cloudflare') {
      let self = this
      window.onloadTurnstileCallback = function () {
        // eslint-disable-next-line
        turnstile.render("#turnstile-container", {
          sitekey: config.recaptchaSiteKey,
          theme: 'light',
          callback: (token) => {
            self.turnstileToken = token
          },
        });
      };
    }
  }
};
</script>
