import config from '@/config';

export const adminService = {
  clearRateLimitByUsername,
  clearRateLimits,
  isAdmin,
  getUserList
};

function clearRateLimits() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(config.authApiUrl+`/auth/admin/rate_limit/clear`, requestOptions)
    .then(handleResponse);
}

function clearRateLimitByUsername(username) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(config.authApiUrl+`/auth/admin/rate_limit/clear/`+username, requestOptions)
    .then(handleResponse);
}

function isAdmin() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(config.authApiUrl+`/auth/is_admin`, requestOptions)
    .then(handleResponse);
}

function getUserList(sortBy, sortDesc, page, itemsPerPage, search) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };
  let queryParams = '';
  if (typeof sortBy !== 'undefined') {
    queryParams = `?sortBy=` + sortBy + `&sortDesc=` + sortDesc + `&page=` + page + `&limit=` + itemsPerPage
  }
  if (typeof search !== "undefined") {
    queryParams += `&search=` + search
  }

  return fetch(config.authApiUrl+`/auth/admin/users` + queryParams, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {

  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        location.reload();
      }

      const error = (data && data.error) || response.statusText;

      return Promise.reject(error);
    }

    return data;
  });
}
