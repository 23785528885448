<template>
  <div>
    <p class="pa-0 mb-0">
      {{$_t(label)}}
      <v-tooltip bottom v-if="tooltip">
        <template v-slot:activator="{ on, attrs }">
      <v-icon small color="darkgrey"
              v-bind="attrs"
              v-on="on">mdi-help-circle</v-icon>
        </template>
        <span>
          {{$_t(tooltip)}}
        </span>
      </v-tooltip>
    </p>
    <v-otp-input
      :length="length"
      type="number"
      :value="value"
      :disabled="submitted"
      :error-messages="errorMessages"
      autocomplete="off"
      :readonly="readonly"
      @focus="readonly = false"
      @blur="readonly = false"
      ontouchstart="this.removeAttribute('readonly');"
      @input="updateValue"
      color="primary"
      :autofocus="autofocus"
    ></v-otp-input>
  </div>
</template>

<script>

export default {
  props: {
    submitted: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    value: {
      type: String
    },
    label: {
      type: String
    },
    length: {
      type: Number
    },
    tooltip: {
      type: String
    },
    errorMessages: {},
    autofocus: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      readonly: true
    }
  },

  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  },
}
</script>
