export default {
    title: process.env.VUE_APP_TITLE || 'User Sign-In',
    logo: process.env.VUE_APP_LOGO || '/img/aptvision/logo.png',
    logoFull: process.env.VUE_APP_LOGO_FULL || '/img/aptvision/logo.png',
    recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY || '',
    appType: process.env.VUE_APP_TYPE || 'patient',
    authApiUrl: process.env.VUE_APP_AUTH_API_URL || 'https://auth_api',
    privacyLink: process.env.VUE_APP_PRIVACY_LINK || false,
    termsLink: process.env.VUE_APP_TERMS_LINK || false,
    simplifiedPatientLogin: (process.env.VUE_APP_SIMPLIFIED_PATIENT_LOGIN === "true"),
    themes: {
        light: {
            primary: process.env.VUE_APP_THEMES_LIGHT_PRIMARY_COLOR || '#1e5674',
            secondary: process.env.VUE_APP_THEMES_LIGHT_SECONDARY_COLOR || '#68bfc5',
            accent: process.env.VUE_APP_THEMES_LIGHT_ACCENT_COLOR || '#82B1FF',
            error: process.env.VUE_APP_THEMES_LIGHT_ERROR_COLOR || '#FF5252',
            info: process.env.VUE_APP_THEMES_LIGHT_INFO_COLOR || '#2196F3',
            success: process.env.VUE_APP_THEMES_LIGHT_SUCCESS_COLOR || '#4CAF50',
            warning: process.env.VUE_APP_THEMES_LIGHT_WARNING_COLOR || '#FFC107'
        }
    },
    identifyPatientByEmail: (process.env.VUE_APP_IDENTIFY_PATIENT_BY_EMAIL === 'true'),
    telDefaultCountry: process.env.VUE_APP_TEL_INPUT_DEFAULT_COUNTRY || 'IE',
    passwordResetRedirect: process.env.VUE_APP_PASSWORD_RESET_REDIRECT || undefined,
    twoFactorEnabled: (process.env.VUE_APP_TWO_FACTOR_ENABLED === 'true'),
    twoFactorAllowedMethods: process.env.VUE_APP_TWO_FACTOR_ALLOWED_METHODS_CSV ? process.env.VUE_APP_TWO_FACTOR_ALLOWED_METHODS_CSV.split(',') : ['sms', 'email'],
    recaptchaEnabled: (process.env.VUE_APP_RECAPTCHA_ENABLED && process.env.VUE_APP_RECAPTCHA_ENABLED === 'true'),
    recaptchaProvider: process.env.VUE_APP_RECAPTCHA_PROVIDER || 'google',
    defaultAppRedirectEnabled: (process.env.VUE_APP_DEFAULT_APP_REDIRECT_ENABLED === 'true'),
    defaultAppRedirectUrl: process.env.VUE_APP_DEFAULT_APP_REDIRECT_URL,
    bcp47LanguageTag: process.env.VUE_APP_BCP47_LANGUAGE_TAG || 'en-US',
    patientFormFields: process.env.VUE_APP_PATIENT_FORM_FIELDS_CSV ? process.env.VUE_APP_PATIENT_FORM_FIELDS_CSV.split(",").filter(x => x) : ['date_of_birth', 'last_name', 'mobile_number'],
    passwordResetEnabled: (process.env.VUE_APP_PASSWORD_RESET_ENABLED === 'true'),
    passwordCreateEnabled: (process.env.VUE_APP_PASSWORD_CREATE_ENABLED === 'true'),
    checkTosEnabled: (process.env.VUE_APP_CHECK_TOS_ENABLED === 'true'),
    tosTemplate: process.env.VUE_APP_TOS_TEMPLATE,
    background: process.env.VUE_APP_BACKGROUND,
    patientLoginByUsernameEnabled: (process.env.VUE_APP_PATIENT_LOGIN_BY_USERNAME_ENABLED === 'true'),
    patientLoginByUsernameFormFields: process.env.VUE_APP_PATIENT_LOGIN_BY_USERNAME_FORM_FIELDS_CSV ? process.env.VUE_APP_PATIENT_LOGIN_BY_USERNAME_FORM_FIELDS_CSV.split(",").filter(x => x) : ['date_of_birth'],
    patientLoginByUsernameFormHeadline: process.env.VUE_APP_PATIENT_LOGIN_BY_USERNAME_FORM_HEADLINE || 'Confirm Your date of birth',
    dateFormat: process.env.VUE_APP_DATE_FORMAT || 'YYYY-MM-DD',
    showRememberMe: (process.env.VUE_APP_SHOW_REMEMBER_ME === 'true'),
    passwordMinLength: process.env.VUE_APP_PASSWORD_MIN_LENGTH || '4',
    googleApiClientId: process.env.VUE_APP_GOOGLE_API_CLIENT_ID
};
