<template>
  <v-container>
    <div class="form-wrapper" v-show="!passwordCreate">
      <div class="subtitle-1 mb-5">{{ $_t('Create password') }}</div>
      <v-form
          @submit.prevent="handleSubmit('createPasswordForm')"
          data-vv-scope="createPasswordForm"
          v-bind:style="{ padding: ($vuetify.breakpoint.xsOnly ? '1em' : 'none') }"
      >
        <div v-for="(inputField, index) in patientFormFields" v-bind:key="inputField">
          <DateOfBirthInput
            v-if="inputField === 'date_of_birth'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="formLoading"
            v-validate="'required'"
            :data-vv-name="inputField"
            :error-messages="$_t(vErrors.collect('createPasswordForm.' + inputField))"
            :autofocus="index === 0"
          ></DateOfBirthInput>
          <MobileNumberInput
            v-else-if="inputField === 'phone_number'"
            v-model="formData[inputField]"
            label="Mobile number"
            :submitted="formLoading"
            :mobile-valid="mobileValid"
            :on-blur-mobile="onBlurMobile"
            :on-validate-mobile="onValidateMobile"
            :autofocus="index === 0"
          ></MobileNumberInput>
          <TextInput
            v-else-if="inputField === 'name'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="formLoading"
            label="Last name"
            icon="mdi-face-man"
            :error-messages="$_t(vErrors.collect('createPasswordForm.' + inputField))"
            v-validate="'required|min:1|max:40'"
            :data-vv-name="inputField"
            :autofocus="index === 0"
          ></TextInput>
          <TextInput
            v-else-if="inputField === 'email'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="formLoading"
            label="Email"
            icon="mdi-email"
            :error-messages="$_t(vErrors.collect('createPasswordForm.' + inputField))"
            v-validate="'email|min:4|max:100'"
            :data-vv-name="inputField"
            :autofocus="index === 0"
          ></TextInput>
          <TextInput
            v-else-if="inputField === 'username'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="formLoading"
            label="Username"
            icon="mdi-face-man"
            :error-messages="$_t(vErrors.collect('createPasswordForm.' + inputField))"
            v-validate="'min:4|max:100'"
            :data-vv-name="inputField"
            :autofocus="index === 0"
          ></TextInput>
          <TextInput
            v-else-if="inputField === 'password'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="formLoading"
            label="Password"
            icon="mdi-lock"
            :password="true"
            :error-messages="$_t(vErrors.collect('createPasswordForm.' + inputField))"
            v-validate="'min:4|max:100'"
            :data-vv-name="inputField"
            :autofocus="index === 0"
          ></TextInput>
          <OTPInput
            v-else-if="inputField === 'username_last_5'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="formLoading"
            label="username_last_5"
            icon="mdi-lock"
            :password="true"
            :error-messages="$_t(vErrors.collect('createPasswordForm.' + inputField))"
            :data-vv-name="inputField"
            :length="5"
            :autofocus="index === 0"
          ></OTPInput>
          <OTPInput
            v-else-if="inputField === 'mobile_last_6'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="formLoading"
            label="mobile_last_6"
            icon="mdi-lock"
            :password="true"
            :error-messages="$_t(vErrors.collect('createPasswordForm.' + inputField))"
            :data-vv-name="inputField"
            :length="6"
            :autofocus="index === 0"
          ></OTPInput>
        </div>
        <TextInput
            v-model="formData['accessCode']"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="submitted"
            label="Access code"
            :error-messages="$_t(vErrors.collect('createPasswordForm.accessCode'))"
            v-validate="'required|min:6|max:100'"
            data-vv-name="accessCode"
        ></TextInput>

        <v-layout align-center pa-0 ma-0 my-5>
          <router-link :to="'login'" style="text-decoration: none">
            <v-btn
                large
                outlined
                class="ma-0 font-weight-regular"
                color="primary"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
              {{$_t('Sign In')}}
            </v-btn>
          </router-link>
          <v-spacer></v-spacer>
          <v-btn
              type="submit"
              large
              class="ma-0"
              :disabled="submitted"
              color="primary"
              light>
            <div v-if="!submitted">
              {{ $_t('Continue') }}
            </div>
            <div v-else>
              <div class="loader"></div>
            </div>
          </v-btn>
        </v-layout>
      </v-form>
    </div>
    <div class="form-wrapper" v-show="passwordCreate">
      <div class="subtitle-1 mb-5">{{ $_t('Verify password') }}</div>
      <v-alert color="green" dark icon="mdi-cellphone-text">
        {{$_t('One time code has been sent to your mobile by SMS, enter the code below along with a new password')}}
      </v-alert>
      <v-form
          @submit.prevent="handleSubmit('verifyCreatePasswordForm')"
          data-vv-scope="verifyCreatePasswordForm"
          v-bind:style="{ padding: ($vuetify.breakpoint.xsOnly ? '1em' : 'none') }"
          autocomplete="off"
      >
        <TextInput
            v-model="formData['oneTimeCode']"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="submitted"
            label="One time code"
            :error-messages="$_t(vErrors.collect('verifyCreatePasswordForm.oneTimeCode'))"
            v-validate="'required|min:6|max:12'"
            data-vv-name="oneTimeCode"
            icon="mdi-cellphone-text"
        ></TextInput>

        <TextInput
            v-model="formData['password']"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="submitted"
            label="New password"
            :error-messages="$_t(vErrors.collect('verifyCreatePasswordForm.password'))"
            v-validate="{ required: true, regex: passwordRegex }"
            data-vv-name="password"
            password
            icon="mdi-lock"
        ></TextInput>

        <TextInput
            v-model="formData['cpassword']"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="submitted"
            label="Confirm new password"
            :error-messages="$_t(vErrors.collect('verifyCreatePasswordForm.cpassword'))"
            v-validate="{ required: true, confirmed: formData['password'] }"
            data-vv-name="cpassword"
            password
            icon="mdi-lock"
        ></TextInput>

        <v-layout align-center pa-0 ma-0 my-5>
          <v-spacer></v-spacer>
          <v-btn
              type="submit"
              large
              class="ma-0"
              :disabled="submitted"
              color="primary"
              light>
            <div v-if="!submitted">
              {{ $_t('Submit') }}
            </div>
            <div v-else>
              <div class="loader"></div>
            </div>
          </v-btn>
        </v-layout>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import config from '@/config';
import DateOfBirthInput from "@/components/Login/Patient/FormInputs/DateOfBirthInput";
import TextInput from "@/components/Login/Patient/FormInputs/TextInput";
import MobileNumberInput from "@/components/Login/Patient/FormInputs/MobileNumberInput";
import OTPInput from "@/components/Login/Patient/FormInputs/OTPInput";

export default {
  components: {
    DateOfBirthInput,
    TextInput,
    MobileNumberInput,
    OTPInput
  },
  data() {
    return {
      config: {'logoFull': '#'},
      username: '',
      accessCode: '',
      password: '',
      cpassword: '',
      oneTimeCode: '',
      passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_+=/\\}{:;'()~`[\]|?.>,<])(?=.{8,})|(?=.{11,})/,
      formData: [],
      autofilled: true,
      patientFormFields: [],
    }
  },
  computed: {
    ...mapState('account', ['status', 'passwordCreate', 'formLoading']),
    submitted() {
      return this.formLoading
    }
  },
  watch: {
    oneTimeCode(val) {
      this.oneTimeCode = val.toUpperCase()
    },
    accessCode(val) {
      this.accessCode = val.toUpperCase()
    }
  },
  created() {
    if (this.status.loggedIn) {
      this.$router.push({path: '/home'});
    }
  },
  methods: {
    ...mapActions('account', [
      'sendPasswordCreateRequest',
      'verifyCreatePasswordRequest'
    ]),
    handleSubmit(scope) {
      this.$validator.validateAll(scope)
        .then((valid) => {
          if (valid) {
            if (scope === 'createPasswordForm') {
              this.sendPasswordCreateRequest(Object.assign({}, this.formData));
            } else {
              this.verifyCreatePasswordRequest(Object.assign({}, this.formData));
            }
          }
        });
    },
  },
  mounted: function () {
    this.config = config;
    let patientFormFields = config.patientFormFields;
    const pwdIndex = patientFormFields.indexOf('password');
    if (pwdIndex > -1) {
      patientFormFields.splice(pwdIndex, 1);
    }
    this.patientFormFields = patientFormFields;
  }
}
</script>
