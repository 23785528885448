<template>
  <v-container>
    <h1 class="headline font-weight-light mb-5" v-show="!status.hideForm">{{ $_t('Reset password')}}</h1>
    <div class="form-wrapper" v-show="!status.passwordTokenValid && !status.passwordResetSuccess">
      <v-form
          v-show="!status.hideForm"
          @submit.prevent="handleSubmit('reset-password-form')"
          data-vv-scope="reset-password-form"
          v-bind:style="{ padding: ($vuetify.breakpoint.xsOnly ? '1em' : 'none') }"
      >
      <div v-for="inputField in config.patientFormFields" v-bind:key="inputField">
        <DateOfBirthInput
            v-if="inputField === 'date_of_birth'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="submitted"
            v-validate="'required'"
            :data-vv-name="inputField"
            :error-messages="$_t(vErrors.collect('reset-password-form.' + inputField))"
        ></DateOfBirthInput>
        <MobileNumberInput
            v-else-if="inputField === 'phone_number'"
            v-model="formData[inputField]"
            label="Mobile number"
            :submitted="submitted"
            :mobile-valid="mobileValid"
            :on-blur-mobile="onBlurMobile"
            :on-validate-mobile="onValidateMobile"
        ></MobileNumberInput>
        <TextInput
            v-else-if="inputField === 'name'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="submitted"
            label="Last name"
            icon="mdi-face-man"
            :error-messages="$_t(vErrors.collect('reset-password-form.' + inputField))"
            v-validate="'required|min:1|max:40'"
            :data-vv-name="inputField"
        ></TextInput>
        <TextInput
            v-else-if="inputField === 'email'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="submitted"
            label="Email"
            icon="mdi-email"
            :error-messages="$_t(vErrors.collect('reset-password-form.' + inputField))"
            v-validate="'email|min:4|max:100'"
            :data-vv-name="inputField"
        ></TextInput>
        <TextInput
            v-else-if="inputField === 'username'"
            v-model="formData[inputField]"
            :placeholder="!autofilled ? ' ' : ''"
            :submitted="submitted"
            label="Username"
            icon="mdi-face-man"
            :error-messages="$_t(vErrors.collect('reset-password-form.' + inputField))"
            v-validate="'min:4|max:100'"
            :data-vv-name="inputField"
        ></TextInput>
      </div>

      <p class="text-center mt-3 body-2" v-if="config.identifyPatientByEmail">{{ $_t('or')}}</p>
      <v-text-field
          v-validate="'email|min:4|max:100'"
          :error-messages="$_t(vErrors.collect('reset-password-form.email'))"
          data-vv-name="email"
          append-icon="mdi-email"
          v-model="formData['email']"
          name="email"
          :label="$_t('E-mail')"
          color="primary"
          outlined
          autocomplete="off"
          :placeholder="!autofilled ? ' ' : ''"
          :disabled="submitted"
          v-if="config.identifyPatientByEmail"
      ></v-text-field>

      <v-layout align-center pa-0 ma-0 my-5>
        <v-spacer></v-spacer>
        <v-btn
            type="submit"
            large
            class="ma-0"
            :disabled="submitted"
            color="primary"
            light>
          <div v-if="!submitted">
            {{ $_t('Reset Password')}}
          </div>
          <div v-else>
            <div class="loader"></div>
          </div>
        </v-btn>
      </v-layout>
    </v-form>
    <div v-show="status.hideForm">
      <v-row>
        <v-icon color="primary" large style="margin: 0 auto;">mdi-check-circle-outline</v-icon>
      </v-row>
      <v-row class="text-xs-center">
        <p class="text-center" style="width: 100%">
          {{$_t('If patient record was found, the password reset instructions will be sent to the email inbox belonging to it')}}
        </p>
      </v-row>
    </div>
  </div>
    <div class="form-wrapper" v-show="status.passwordTokenValid || status.passwordResetSuccess">
      <v-form
          @submit.prevent="handleSubmit('change-password-form')"
          data-vv-scope="change-password-form"
          v-bind:style="{ padding: ($vuetify.breakpoint.xsOnly ? '1em' : 'none') }"
          v-show="!status.hideForm"
      >
        <v-text-field
            v-validate="{ required: true, regex: passwordRegex }"
            :error-messages="$_t(vErrors.collect('change-password-form.password'))"
            data-vv-name="password"
            append-icon="mdi-lock"
            v-model="password"
            name="password"
            :label="$_t('Password')"
            color="primary"
            outlined
            autocomplete="none"
            ref="password"
            type="password"
        ></v-text-field>

        <v-text-field
            v-validate="{ required: true, confirmed: 'password', regex: passwordRegex }"
            :error-messages="$_t(vErrors.collect('change-password-form.cpassword'))"
            data-vv-name="cpassword"
            append-icon="mdi-lock"
            v-model="cpassword"
            name="cpassword"
            :label="$_t('Confirm password')"
            color="primary"
            outlined
            autocomplete="none"
            type="password"
        ></v-text-field>

        <v-layout align-center pa-0 ma-0>
          <router-link :to="'login'" style="text-decoration: none">
            <v-btn
                large
                outlined
                class="ma-0"
                color="primary"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
              {{$_t('Sign in')}}
            </v-btn>
          </router-link>
          <v-spacer></v-spacer>
          <v-btn
              type="submit"
              large
              :disabled="submitted"
              color="primary"
              class="ma-0">
            <div v-if="!submitted">
              {{ $_t('Save') }}
            </div>
            <div v-else>
              <div class="loader"></div>
            </div>
          </v-btn>
        </v-layout>
      </v-form>
      <div v-show="status.hideForm">
        <v-row>
          <v-icon color="primary" large style="margin: 0 auto;">mdi-check-circle-outline</v-icon>
        </v-row>
        <v-row class="text-xs-center">
          <p class="text-center" style="width: 100%">
            <br/>
            {{ $_t('Your password has been reset.') }}
            <span v-if="config.passwordResetRedirect">
                {{ $_t("You will now be redirected to the application. Use the link below if you're not redirected in 5 seconds:") }}<br/>
                <a :href="config.passwordResetRedirect">{{config.passwordResetRedirect}}</a>
              </span>
            <span v-else>
                {{ $_t('Visit the application again and use your newly created password to log in.') }}
              </span>
          </p>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import config from '@/config';
import DateOfBirthInput from "@/components/Login/Patient/FormInputs/DateOfBirthInput";
import TextInput from "@/components/Login/Patient/FormInputs/TextInput";
import MobileNumberInput from "@/components/Login/Patient/FormInputs/MobileNumberInput";

export default {
  components: {
    DateOfBirthInput,
    TextInput,
    MobileNumberInput
  },
  data () {
    return {
      submitted: false,
      config: {'logoFull': '#'},
      password: '',
      cpassword: '',
      token: '',
      hideForm: false,
      passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\-_+=/\\}{:;'()~`[\]|?.>,<])(?=.{8,})|(?=.{11,})/,
      formData: [],
      autofilled: false,
      mobileValid: true,
      mobileValidTemp: null
    }
  },
  computed: {
    ...mapState('account', ['status'])
  },
  created () {
    if (this.status.loggedIn) {
      this.$router.push({ path: '/home' });
    } else {
      this.token = this.$route.query.token;

      if (this.token !== undefined && this.token !== '' && !this.status.hideForm) {
        this.verifyResetPasswordTokenRequest(this.token);
      }
    }
  },
  methods: {
    ...mapActions('account', [
      'sendPatientPasswordResetRequest',
      'verifyResetPasswordTokenRequest',
      'changePasswordRequest'
    ]),
    handleSubmit(scope) {
      this.submitted = true;

      this.$validator.validateAll(scope)
          .then((valid) => {
            if (valid) {
              if (scope === 'reset-password-form') {
                this.sendPatientPasswordResetRequest(Object.assign({}, this.formData));
              } else {
                this.changePasswordRequest({ password: this.password, token: this.token });
              }
            } else {
              this.submitted = false;
            }
          });
    },
    onValidateMobile({ isValid }) {
      if (this.mobileValidTemp !== null) {
        this.mobileValid = isValid;
      }
      this.mobileValidTemp = isValid;
    },
    onBlurMobile() {
      this.mobileValid = this.mobileValidTemp;
    },
  },
  mounted: function () {
    this.config = config;
  }
}
</script>
