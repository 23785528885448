<template>
  <v-container fluid fill-height pa-0 ma-0 v-show="!status.loading">
    <v-layout row wrap pa-0>
      <v-flex xs12 sm8 md5 lg3 style="margin: 0 auto;">
        <v-container fluid fill-height>
          <v-card class="elevation-12 py-5 px-4" color="rgba(255,255,255,0.8)" style="width:100%">
            <p class="text-center">
              <img :src="(branding.use_logo_url) ? branding.brand_logo_url : branding.brand_logo_base64" style="max-width:200px; max-height: 100px;" alt="logo"/>
            </p>
            <!-- Login form -->
            <div class="form-wrapper">
              <PatientIdentifierForm v-if="!twoFactor.enabled"/>
              <PatientTwoFactorForm v-if="twoFactor.enabled && config.twoFactorEnabled && !twoFactor.completed"></PatientTwoFactorForm>
              <PatientTermsOfServiceForm
                v-if="config.checkTosEnabled && twoFactor.completed && !isTosAccepted"></PatientTermsOfServiceForm>
            </div>
            <div v-if="config.privacyLink || config.termsLink" style="float:right">
              <a v-ripple class="primary--text v-btn v-btn--text v-size--x-small mb-2 pa-4" style="white-space: normal; text-align: right; float:right;" v-if="config.privacyLink" :href="config.privacyLink" target="_blank">
                {{ $_t('Privacy') }}
              </a>
              <a v-ripple class="primary--text v-btn v-btn--text v-size--x-small mb-2 pa-4" style="white-space: normal; text-align: right; float:right;" v-if="config.termsLink" :href="config.termsLink" target="_blank">
                {{ $_t('Terms') }}
              </a>
            </div>
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';
import PatientIdentifierForm from "@/components/Login/Patient/PatientIdentifierForm";
import PatientTwoFactorForm from "@/components/Login/Patient/PatientTwoFactorForm";
import PatientTermsOfServiceForm from "@/components/Login/Patient/PatientTermsOfServiceForm";
import config from '@/config';

export default {
  components: {
    PatientIdentifierForm,
    PatientTwoFactorForm,
    PatientTermsOfServiceForm
  },
  data() {
    return {
      config: {'logoFull': '#'}
    }
  },
  computed: {
    ...mapState('account', ['status', 'patient', 'redirectLink', 'isTosAccepted', 'twoFactor']),
    ...mapState('brand', ['branding'])
  },
  created() {
    if (this.status.loggedIn) {
      this.$router.push({path: '/'});
    }
  },
  methods: {},
  mounted: function () {
    this.config = config;
  },
  destroyed() {
  }
}
</script>
