<template>
  <v-container fluid fill-height pa-0 ma-0 :style="style" v-show="!status.loading">
    <v-layout row wrap pa-0>
      <v-flex xs12 sm8 md5 lg3 style="margin: 0 auto;">
        <v-container fluid fill-height>
          <v-card class="elevation-12 py-5 px-4" color="rgba(255,255,255,0.8)" style="width:100%">
            <p class="text-center">
              <img :src="config.logoFull" style="max-width:200px; max-height: 100px;"/>
            </p>
            <UserResetPasswordForm/>
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import config from '@/config';
import UserResetPasswordForm from "@/components/Login/User/UserResetPasswordForm";
import {mapState} from "vuex";

export default {
  components: {
    UserResetPasswordForm
  },
  data() {
    return {
      config: {'logoFull': '#', 'background': false},
    }
  },
  mounted() {
    this.config = config;
  },
  computed: {
    ...mapState('account', ['status']),
    style() {
      if (this.config.background) {
        return {
          'background-size': 'cover',
          'background-image': 'url("' + this.config.background + '")'
        }
      }
      return ''
    }
  }
}
</script>
