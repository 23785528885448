export default {
  "en": {
    "sms": "SMS",
    "privacy_user": "Privacy",
    "terms_user": "Terms",
    "social_login.link_existing.title": "Link your {method} account with the existing user?",
    "social_login.link_existing.text": "We found an account matching your {method} email. By linking your account you will be able to login using Google Sign-In. Once the accounts are linked, you will no longer receive this prompt."
  },
  "pl": {
    "Your details": "Twoje dane",
    "Date of birth": "Data urodzenia",
    "E-mail": "E-mail",
    "The date of birth field is required": "'Data urodzenia' jest wymagana.",
    "Last name": "Nazwisko",
    "The last name field is required": "Pole 'Nazwisko' jest wymagane.",
    "Phone number or E-mail is required": "Poprawny numer kontaktowy lub adres e-mail jest wymagany.",
    "Phone number is required": "Poprawny numer kontaktowy jest wymagany.",
    "Phone number is not valid": "Wprowadź poprawny telefon kontaktowy.",
    "The accessCode field is required": "Pole 'Specjalny kod dostępu' jest wymagane.",
    "The username field is required": "Pole 'Użytkownik' jest wymagane.",
    "The oneTimeCode field is required": "Pole Jednorazowy kod dostępu jest wymagane.",
    "The password field is required": "Pole 'Hasło' jest wymagane.",
    "Password confirmation required": "Pole 'Potwierdź nowe hasło' jest wymagane.",
    "Both passwords must match": "Wprowadzone hasła nie są jednakowe.",
    "Next": "Kontynuuj",
    "Password": "Hasło",
    "Continue": "Kontynuuj",
    "Mobile number": "Telefon kontaktowy",
    "Close": "Zamknij",
    "Forgot your password?": "Pierwsze logowanie / resetuj hasło",
    "Username or E-mail": "Użytkownik",
    "Reset password": "Resetuj / utwórz hasło",
    "Reset Password": "Kontynuuj",
    "Create password": "Krok 1/2 - Pierwsze logowanie / resetuj hasło",
    "Verify password": "Krok 2/2 - Utwórz hasło",
    "Code verified, logging in": "Kod prawidłowy, trwa logowanie.",
    "Invalid code": "Wprowadzony kod jest niepoprawny.",
    "Error: At least 3 identifiers should be passed": "Wprowadź wymagane dane w pola poniżej.",
    "The username field must be at least 4 characters": "Wprowadź co najmniej 4 znaki.",
    "The password field must be at least 4 characters": "Wprowadź co najmniej 4 znaki.",
    "Successfully logged in": "Logowanie zakończone pomyślnie.",
    "ERROR_MESSAGE_PATIENT_NOT_FOUND": "Niestety nie udało nam się odnaleźć rekordu dla wprowadzonych danych.",
    "Patient found": "Dziękujemy, wprowadzone dane są prawidłowe.",
    "Select how you would like to receive the two factor code:": "Wybierz sposób w jaki chcesz otrzymać jednorazowy kod dostępu:",
    "Go back to Login": "Wróć do ekranu logowania",
    "Go back Sign In": "Wróć do ekranu logowania",
    "Back To Sign In": "Wróć do ekranu logowania",
    "Send by SMS": "Wyślij przez SMS",
    "Send by Email": "Wyślij przez Email",
    "Code sent by email": "Jednorazowy kod dostępu został wysłany przez Email.",
    "Code sent by sms": "Jednorazowy kod dostępu został wysłany przez SMS.",
    "Two factor code": "Jednorazowy kod dostępu",
    "Sign In": "Logowanie",
    "Login": "Zaloguj",
    "Password reset request received. Check your inbox": "Dziękujemy. Na wskazany adres e-mail wysłaliśmy intrukcję do utworzenia / resetowania hasła.",
    "Check your inbox!": "E-mail został wysłany.",
    "If patient record was found, the password reset instructions will be sent to the email inbox belonging to it": "If patient record was found, the password reset instructions will be sent to the email inbox belonging to it",
    "Enter your new password": "Wprowadź nowe hasło",
    "Password reset token has expired, request a new one by entering your email": "Password reset token has expired, request a new one by entering your email",
    "Password has been changed": "Hasło zostało zmienione.",
    "Could not change the password. Try again": "Zmiana hasła nie powiodła się. Spróbuj ponownie.",
    "Password has been created, You will now be redirected to the login page...": "Hasło zostało utworzone. Trwa logowanie do portalu...",
    "The oneTimeCode field must be at least 6 characters": "Jednorazowy kod dostępu powinien zawierać co najmniej 6 znaków.",
    "Password should be at least 8 characters long and have at least one of each: lower-case & upper-case letter, a number and a special character OR be at least 11 characters in length": "Hasło musi mieć co najmniej 8 znaków oraz zawierać przynajmniej jedną: małą literę, wielką literę, cyfrę oraz znak specjalny lub zawierać minimum 11 znaków.",
    "Failed to create a password, make sure the code entered is correct": "Nie można utworzyć nowego hasła, upewnij się, czy wprowadzony jednorazowy kod jest prawidłowy.",
    "Username": "Użytkownik",
    "Access code": "Specjalny kod dostępu",
    "One time code has been sent to your mobile by SMS, enter the code below along with a new password": "Jednorazowy kod dostępu został wysłany na numer telefonu przypisany do użytkownika. Wprowadź go poniżej oraz utwórz nowe hasło umożliwiające dostęp do portalu.",
    "One time code": "Jednorazowy kod dostępu",
    "New password": "Nowe hasło",
    "Confirm new password": "Potwierdź nowe hasło",
    "Confirm password": "Potwierdź nowe hasło",
    "Save": "Zapisz",
    "Set up password": "Pierwsze logowanie / resetuj hasło",
    "Incorrect details provided": "Wprawdzone dane są niepoprawne.",
    "Submit": "Kontynuuj",
    "Your one time verification code is:": "Jednorazowy kod dostępu to:",
    "A one time code has been sent to you by": "Jednorazowy kod dostępu został wysłany przez",
    "Please enter it below and click 'Login'": "Wprowadź go poniżej i kliknij Zaloguj.",
    "Could not find a valid email or mobile number associated with the account, therefore the two factor code cannot be delivered. Contact your IT team in order to complete Your account details.": "Could not find a valid email or mobile number associated with the account, therefore the two factor code cannot be delivered. Contact your IT team in order to complete Your account details.",
    "sms": "SMS",
    "I accept": "Akceptuję Regulamin",
    "Code verified, please accept terms of service in order to continue": "Dziękujemy, wprowadzony jednorazowy kod jest prawidłowy. Prosimy o zapoznanie się i akceptację regulaminu portalu.",
    "Sending two factor code...": "Wysyłamy kod jednorazowy...",
    "Could not successfully send a two factor code.": "Wysyłanie kodu jednorazowego nie powiodło się",
    "Try again": "Spróbuj ponownie",
    "Back to sign in": "Wróć do ekranu logowania",
    "privacy_user": "Informacja o ochronie danych osobowych",
    "terms_user": "Regulamin Portalu Lekarza",
    "username_last_5": "Pięć ostatnich cyfr numeru PESEL",
    "mobile_last_6": "Sześć ostatnich cyfr numeru telefonu",
    "Privacy": "Polityka Prywatności i Bezpieczeństwa Danych",
    "Terms": "Regulamin Portalu Pacjenta",
    "social_login.link_existing.title": "Link your {method} account with the existing user?",
    "social_login.link_existing.text": "We found an account matching your {method} email. By linking your account you will be able to login using Google Sign-In. Once the accounts are linked, you will no longer receive this prompt."
  },
  "hu": {
    "E-mail": "E-mail",
    "The identity field is required": "Az azonosító megadása kötelező",
    "The password field is required": "A jelszó mező kitöltése kötelező",
    "Password confirmation required": "Erősítse meg jelszavát",
    "Both passwords must match": "Mindkét jelszónak egyeznie kell",
    "Next": "Következő",
    "Password": "Jelszó",
    "Continue": "Folytatás",
    "Close": "Bezárás",
    "Forgot your password?": "Elfelejtette jelszavát?",
    "Username or E-mail": "Felhasználónév vagy e-mail",
    "Code verified, logging in": "Kód elfogadva, bejelentkezés",
    "Invalid code": "Érvénytelen kód",
    "The username field must be at least 4 characters": "A felhasználónév legalább 4 karaktert tartalmazzon",
    "The password field must be at least 4 characters": "A jelszó legalább 4 karaktert tartalmazzon",
    "Successfully logged in": "Sikeres bejelentkezés",
    "Select how you would like to receive the two factor code:": "Kérem, válassza ki, hogy szeretné megkapni a kétfaktoros autentikáció kódját:",
    "Go back to Login": "Vissza a bejelentkezéshez",
    "Go back Sign In": "Vissza a bejelentkezéshez",
    "Back To Sign In": "Vissza a bejelentkezéshez",
    "Send by SMS": "SMS-ben",
    "Send by Email": "E-mailben",
    "Code sent by email": "A kód e-mailben elküldve",
    "Code sent by sms": "A kód sms-ben elküldve",
    "Two factor code": "Két faktoros autentikáció kódja",
    "Sign In": "Bejelentkezés",
    "Login": "Belépés",
    "Password reset request received. Check your inbox": "Jelszó visszaállítási kérés érkezett. Ellenőrizze a bejövő e-mailjeit",
    "Check your inbox!": "Ellenőrizze a bejövő e-mailjeit!",
    "Enter your new password": "Adja meg az új jelszavát",
    "Password reset token has expired, request a new one by entering your email": "A jelszó visszaállításhoz a link lejárt, az új linkhez küldéséhez adja meg e-mail címét",
    "Password has been changed": "A jelszó megváltozott",
    "Could not change the password. Try again": "A jelszó módosítás nem lehetséges. Próbálja újra",
    "The oneTimeCode field must be at least 6 characters": "Az egyszeri kód legalább 6 karaktert tartalmazzon",
    "Password should be at least 8 characters long and have at least one of each: lower-case & upper-case letter, a number and a special character OR be at least 11 characters in length": "A jelszó legalább 8 karaktert tartalmazzon és legalább az egyik legyen benne ezek közül: kisbetű & nagybetű, szám és speicális karakter VAGY legalább 11 karakter hosszú legyen",
    "Username": "Felhasználónév",
    "New password": "Új jelszó",
    "Confirm new password": "Új jelsző megerősítése",
    "Confirm password": "Jelszó megerősítése",
    "Save": "Mentés",
    "Incorrect details provided": "Érvénytelen adat",
    "Submit": "Belépés",
    "sms": "SMS",
    "Sending two factor code...": "Két faktoros autentikációhoz kód küldése...",
    "Could not successfully send a two factor code.": "A kétfaktoros autentikáció kódjának küldése sikertelen.",
    "Try again": "Próbálja újra",
    "Back to sign in": "Vissza a bejelentkezéshez",
    "Reset password": "Jelszó visszaállítása",
    "User found": "Felhasználó beazonosítva",
    "A one time code has been sent to you by": "Az egyszer használható jelszót elküldtük Önnek",
    "Please enter it below and click 'Login'": "Kérjük írja be a jelszót és kattintson a 'Belépés' gombra",
    "You will now be redirected to the application. Use the link below if you're not redirected in 5 seconds:": "A jelszavát visszaállítottuk. Visszairányítjuk az alkalmazáshoz. Használja az alábbi linket, ha 5 másodpercen belül nem történik meg az átirányítás automatikusan.",
    "Your password has been reset.": "A jelszavát visszaállítottuk.",
    "The password reset token has already been used or is invalid": "A jelszó visszaállítási tokent már használták vagy érvénytelen",
    "social_login.link_existing.title": "Link your {method} account with the existing user?",
    "social_login.link_existing.text": "We found an account matching your {method} email. By linking your account you will be able to login using Google Sign-In. Once the accounts are linked, you will no longer receive this prompt."
  }
}
