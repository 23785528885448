import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import App from './App.vue';
import router from './helpers/router';
import { store } from './store/index';
import VeeValidate from 'vee-validate';
import vueHeadful from 'vue-headful';
import i18n from "@/i18n";
import translate from '@/helpers/translate'

/* Extend the validator with a custom email or number rule */
const phoneOrEmailRule = {
    getMessage() {
        return `Invalid email/phone number`;
    },
    validate(value) {

        // Custom regex for a phone number
        const MOBILEREG = /^(([0-9])+\d{8})$/;
        const EMAILREG = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;

        // Check for either of these to return true
        return EMAILREG.test(value) || MOBILEREG.test(value);
    }
};

VeeValidate.Validator.extend('phoneOrEmail', phoneOrEmailRule);

const validateConfig = {
    errorBagName: 'vErrors',
    events: 'change',
    locale: 'en',
    dictionary: {
        en : {
            attributes: {
                cpassword: 'password confirmation',
                date_of_birth: 'date of birth',
                last_name: 'last name'
            },
            custom: {
                password: {
                    min: (name, min) => 'The password field must be at least '+min+' characters',
                    regex: () => 'Password should be at least 8 characters long and have at least one of each: lower-case & upper-case letter, a number and a special character OR be at least 11 characters in length'
                },
                cpassword: {
                    min: (name, min) => 'Minimum of '+min+' chars',
                    required: () => 'Password confirmation required',
                    confirmed: () => 'Both passwords must match'
                },
                tos: {
                    is: 'Terms of Service & Privacy Policy must be accepted'
                }
            }
        }
    }
};

Vue.use(VeeValidate, validateConfig);
Vue.use(translate);

Vue.component('vue-headful', vueHeadful);

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
