<template>
  <v-container>
    <h1 class="headline font-weight-light mb-5" v-if="!isAdminLogin">{{$_t('Sign In')}}</h1>
    <v-form @submit.prevent="handleSubmit('login-form')" data-vv-scope="login-form" v-bind:style="{ padding: ($vuetify.breakpoint.xsOnly ? '1em' : 'none') }">
      <v-text-field
        v-validate="'required|min:4|max:100'"
        :error-messages="vErrors.collect('login-form.identity')"
        data-vv-name="identity"
        append-icon="mdi-account-outline"
        v-model="username"
        name="email"
        :label="$_t('Username or E-mail')"
        color="primary"
        outlined
        placeholder=" "
        persistent-placeholder
        :disabled="status.loginRequest || formLoading"
        tabindex="1"
        :autofocus="autofocus === 'username'"
        autocomplete="username"
      ></v-text-field>

      <v-text-field
        v-validate="'required|min:' + config.passwordMinLength + '|max:30'"
        :error-messages="vErrors.collect('login-form.password')"
        data-vv-name="password"
        ref="password"
        append-icon="mdi-lock"
        v-model="password"
        name="password"
        :label="$_t('Password')"
        type="password"
        color="primary"
        light
        outlined
        placeholder=" "
        persistent-placeholder
        :disabled="status.loginRequest || formLoading"
        tabindex="2"
        :autofocus="autofocus === 'password'"
        autocomplete="current-password"
      ></v-text-field>

      <Captcha ref="captcha" v-if="isRecaptchaEnabled" @handleLogin="handleLogin"></Captcha>

      <v-checkbox
        v-model="rememberMe"
        :label="$_t('Remember me')"
        class="mt-0"
        tabindex="3"
        v-if="config.showRememberMe"
        dense
      ></v-checkbox>

      <v-layout align-center pa-0 ma-0>
        <router-link :to="'reset-password'" tabindex="4" v-if="config.passwordResetEnabled">
          <a style="text-decoration: underline">{{$_t('Forgot your password?')}}</a>
        </router-link>
        <v-spacer></v-spacer>
        <v-btn
          tabindex="3"
          type="submit"
          large
          class="ma-0"
          :loading="status.loginRequest"
          color="primary"
          light
          :disabled="formLoading"
        >
          {{ $_t('Login') }}
        </v-btn>
      </v-layout>
    </v-form>
    <div>
      <SocialLogin :disabled="!formLoading"/>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import config from '@/config';
import SocialLogin from './../../SocialLogin'
import device from '@/helpers/device'
import Captcha from "@/components/core/Captcha.vue";

export default {
  components: {
    Captcha,
    SocialLogin
  },
  props: {
    isAdminLogin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      username: '',
      password: '',
      code: '',
      config: {'logoFull': '#'},
      rememberMe: false,
      type: 'native',
      autofocus: 'username',
    }
  },
  computed: {
    device() {
      return device
    },
    ...mapState('account', ['status', 'formLoading']),
    dynamicWidth: function() {
      return this.$vuetify.breakpoint.xsOnly ? {maxWidth: '330px'} : {};
    },
    isRecaptchaEnabled() {
      if (!config.recaptchaEnabled) {
        return false
      }

      return this.status.recaptchaRequired
    }
  },
  created () {
  },
  methods: {
    ...mapActions('account', ['loginRequest', 'submitUserIdentity']),
    ...mapActions('alert', ['error']),
    handleSubmit(scope) {
      this.$validator.validateAll(scope)
        .then((valid) => {
          if (valid) {
            if (this.isRecaptchaEnabled && !device.isCookieSetForUsername(this.username)) {
              this.$refs.captcha.verify()
            } else {
              this.handleLogin('1'); // recaptcha disabled, providing a random string to bypass validator
            }
          }
        });
    },
    loginCallback(isSuccess) {
      if (this.isRecaptchaEnabled) {
        this.$refs.captcha.resetRecaptcha()
      }
      if (!isSuccess) {
        this.$nextTick(() => { this.$refs.password.$refs.input.focus() });
      }
    },
    handleLogin(token) {
      this.loginRequest();
      let loginObject = {
        usernameOrEmail: this.username,
        password: this.password,
        recaptchaToken: token,
        rememberMe: this.rememberMe,
        isAdminLogin: this.isAdminLogin,
        isIframe: this.type === 'iframe',
        callback: this.loginCallback
      }

      this.submitUserIdentity(loginObject);
    },
    showError(message) {
      this.error(message)
    }
  },
  mounted: function () {
    this.config = config;
    if (config.showRememberMe && localStorage.getItem('loginForm.username')) {
      this.username =  localStorage.getItem('loginForm.username');
      this.rememberMe = true;
    }
    if (this.$route.query.username) {
      this.username = this.$route.query.username
      this.autofocus = 'password'
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
  },
  destroyed() {
  }
}
</script>
