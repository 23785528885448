<template>
  <vue-tel-input
      @input="updateValue"
      @onValidate="onValidateMobile"
      inputClasses="phone-margin-fix v-input__slot"
      style="color: #000;line-height:2.3em;"
      :placeholder="$_t(label)"
      v-bind:style="{
            'border-color': (mobileValid || !value ? 'rgba(0,0,0,.42)' : '#ff5252'),
            'border-width': (mobileValid || !value ? '1px' : '2px'),
          }"
      @onBlur="onBlurMobile"
      mode="international"
      :disabled="submitted"
      :defaultCountry="config.telDefaultCountry"
      :autofocus="autofocus"
  ></vue-tel-input>
</template>

<script>

import config from "@/config";
import { VueTelInput } from 'vue-tel-input'

export default {
  props: {
    submitted: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    label: {
      type: String
    },
    onValidateMobile: {
      type: Function
    },
    onBlurMobile: {
      type: Function
    },
    mobileValid: {
      type: Boolean
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },

  components: {
    VueTelInput
  },

  data () {
    return {
      config: config
    }
  },

  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style>
.phone-margin-fix {
  margin-top:10px !important;
}
.vue-tel-input:focus-within {
  box-shadow: none !important;
}
.vue-tel-input {
  border:1px solid rgba(0,0,0,.42);
}
.vue-tel-input:hover {
  border-color:#000 !important;
}
.vue-tel-input:focus {
  background-color: black;
  border-color: yellow !important;
}
</style>
