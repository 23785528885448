import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { account } from './account.module';
import { client } from './client.module';
import { admin } from './admin.module';
import { brand } from './brand.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        alert,
        account,
        client,
        admin,
        brand
    }
});
