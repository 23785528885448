<template>
  <v-app id="vue-app">
    <!-- HTML title -->
    <div>
      <vue-headful
        :title="brand.branding.title"
        :description="brand.branding.title"
      />
    </div>
    <!-- Authenticated template -->
    <template v-if="account.status.loggedIn && !account.status.loading">
      <v-app-bar
        v-show="!account.status.loading"
        color="#f0f0f0"
        fixed
        clipped-left>
        <v-btn icon style="margin-left:-2px;">
          <v-img :src="(brand.branding.use_logo_url) ? brand.branding.brand_logo_url : brand.branding.brand_logo_base64"
                 width="20px"></v-img>
        </v-btn>
        <span class="title ml-3 mr-5 hidden-sm-and-down" style="color:#005caa;">{{ config.title }}</span>
        <v-spacer></v-spacer>
        <v-btn outlined color="primary" v-on:click="logout">Logout</v-btn>
      </v-app-bar>
      <v-main v-show="!account.status.loading">
        <v-container fluid fill-height pa-0>
          <v-layout justify-center align-beginning>
            <router-view/>
          </v-layout>
        </v-container>
      </v-main>
      <core-loading :message="loadingMessage" v-show="account.status.loading"></core-loading>
    </template>

    <!-- Guest template -->
    <template v-else-if="!account.status.loggedIn">
      <v-main pa-0 v-show="!account.status.loading">
        <v-container fluid fill-height id="login-wrapper" pa-0>
          <router-view/>
        </v-container>
      </v-main>
      <core-loading v-show="account.status.loading" :message="loadingMessage"></core-loading>
    </template>

    <!-- Alert popup -->
    <v-snackbar :value="alert.message" :color="alert.type" :timeout="alert.timeout" multi-line top>
      <div class="alert" v-if="alert.message">
        {{ $_t(alert.message.message) }}
      </div>
      <div class="text-center">
        <v-btn
          outlined
          small
          class="ml-4"
          @click="alert.message = null"
        >
          {{ $_t('Close') }}
        </v-btn>
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import config from '@/config';
import CoreLoading from '@/components/core/Loading'

export default {
  name: 'App',
  components: {
    CoreLoading
  },
  data() {
    return {
      config: {title: "Loading...", logo: "#"},
      loadingMessage: {status: 'INFO', message: ''}
    }
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      logout: 'account/logout',
      isLoggedIn: 'account/isLoggedIn',
      loaded: 'account/loaded',
      getBranding: 'brand/getBranding',
      errorAlert: 'alert/error'
    }),
    goTo: function (path) {
      this.$router.push({path: path});
    }
  },
  mounted: function () {
    this.config = config;
    const code = this.$route.query.bc || this.$route.query.client_id
    if (code) {
      this.getBranding({code: code, callback: this.loaded});
    } else {
      window.addEventListener('load', () => {
        // run after everything is in-place
        this.loaded();
      }, {once: true})
    }

    if (this.$route.query.err_msg && (typeof this.$route.query.err_msg === 'string' || this.$route.query.err_msg instanceof String)) {
      this.errorAlert({message: decodeURIComponent(this.$route.query.err_msg)})
    }
  },
  computed: {
    ...mapState({
      account: state => state.account,
      alert: state => state.alert,
      brand: state => state.brand
    })
  },
  watch: {
    $route() {
      this.clearAlert();
    }
  },
  created: function () {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.drawer = false;
    }
    this.isLoggedIn();
  }
}
</script>

<style lang="scss">
.theme--dark.v-text-field--outline .v-input__slot {
  border-color: rgba(255, 255, 255, 0.9) !important;
}

.alert {
  padding: 20px;
  font-size: 1.2em;
  text-align: center;
  margin: 0 auto;
}

/*Change text in autofill textbox*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s, color 9999s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  color: #fff;
}

.g-recaptcha {
  display: inline-block;
}
</style>
<style lang="sass">
@import '../node_modules/fontsource-roboto/index.css'
@import '../node_modules/fontsource-roboto/100.css'
@import '../node_modules/fontsource-roboto/300.css'
@import '../node_modules/fontsource-roboto/400.css'
@import '../node_modules/fontsource-roboto/500.css'
@import '../node_modules/fontsource-roboto/700.css'
@import '../node_modules/fontsource-roboto/900.css'
</style>
