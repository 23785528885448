<template>
  <v-container pa-0 v-if="socialLogin.google" class="text-center">
    <br/>
    <span class="font-weight-bold" style="color:#1e5674">or</span>
    <br/><br/>
    <div style="width: 100%">
      <div id="g_id_onload"
           :data-client_id="config.googleApiClientId"
           data-auto_prompt="false"
           data-callback="handleCredentialResponse"
      ></div>
      <div class="g_id_signin"
           data-type="standard"
           data-size="large"
           data-theme="outline"
           data-text="sign_in_with"
           data-shape="rectangular"
           data-logo_alignment="left"
           style="width: fit-content; margin: 0 auto"
      ></div>
    </div>
    <br/>
    <v-dialog
      v-model="linkUserPrompt"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 lighten-2" style="word-break: break-word">
          {{ $_t('social_login.link_existing.title', {method: method}) }}
        </v-card-title>

        <v-card-text style="word-break: break-word">
          {{ $_t('social_login.link_existing.text', {method: method}) }}
          {{$_t('We found an account matching your')}} {{method}} {{$_t('email. By linking your account you will be able to login using Google Sign-In. Once the accounts are linked, you will no longer receive this prompt.')}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="linkUserPrompt = false"
          >
            {{$t('Cancel')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="linkAccount"
          >
            {{$t('Link my accounts')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import config from '@/config';

export default {
  components: {
  },
  data() {
    return {
      username: '',
      password: '',
      code: '',
      submitted: false,
      googleEnabled: false,
      config: config,
      authResponse: null,
      socialLoginObject: {},
      linkUserPrompt: false,
      method: ''
    }
  },
  computed: {
    ...mapState('account', ['status', 'socialLogin']),
    dynamicWidth: function () {
      return this.$vuetify.breakpoint.xsOnly ? {maxWidth: '330px'} : {};
    }
  },
  created() {
    if (this.status.loggedIn) {
      this.$router.push({path: '/home'});
    }
  },
  methods: {
    ...mapActions('account', ['doSocialLogin', 'isGoogleLoginEnabled']),
    loadGoogleScript: function() {
      const googleSignInScript = document.createElement('script');
      googleSignInScript.setAttribute(
        'src',
        'https://accounts.google.com/gsi/client'
      );
      googleSignInScript.setAttribute('async', 'true');
      googleSignInScript.setAttribute('defer', 'true');
      document.head.appendChild(googleSignInScript);
    },
    googleSignIn: function () {
      window.gapi.auth2.getAuthInstance().signIn();
    },
    handleSocialLogin() {
      this.method = 'Google';
      this.socialLoginObject = {
        type: "google",
        id_token: this.authResponse.credential,
        promptCallback: this.promptCallback
      }
      this.doSocialLogin(this.socialLoginObject);
    },
    promptCallback () {
      this.linkUserPrompt = true;
    },
    linkAccount() {
      this.socialLoginObject.link = true
      this.doSocialLogin(this.socialLoginObject)
    },
  },
  mounted: function () {
    window.handleCredentialResponse = (response) => {
      this.authResponse = response
      this.handleSocialLogin()
    }

    this.isGoogleLoginEnabled((result) => {
      if (result) {
        this.loadGoogleScript()
      }
    });
  },
  destroyed() {
  }
}
</script>
