<template>
    <v-container fluid fill-height pa-0 pt-16>
        <v-layout fill-height pa-0>
            <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly, 'pa-5': $vuetify.breakpoint.smAndUp}">
                <v-layout :column="$vuetify.breakpoint.smAndDown" :row="$vuetify.breakpoint.mdAndUp" wrap>
                    <v-flex xs12 md12 lg12 xl12 class="text-xs-center">
                        <div class="font-weight-thin" style="margin: 15px auto; font-size: 175%;text-align:center;">
                            You are now logged in to {{config.title}}.<br/><br/>
                            Feel free to use any of our API related resources!<br/><br/>
                            You might be redirected here from time to time to approve access to our back-end services.
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-layout>
    </v-container>
</template>

<script>
    import config from '@/config';
    export default {
        data: function() {return {
            config: {title: ''}
        }},
        computed: {

        },
        watch: {
        },
        created() {
        },
        mounted() {
            this.config = config;
        }
    }
</script>
