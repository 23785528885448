<template>
  <v-container>
    <div v-if="twoFactor.sent && !twoFactor.error">
      <p class="text-sm-body-2 pa-1" v-bind:style="{ maxWidth: ($vuetify.breakpoint.xsOnly ? '260px' : 'none') }" style="margin:0 auto;">
        {{$_t('A one time code has been sent to you by')}} {{$_t(twoFactor.method)}}. {{$_t("Please enter it below and click 'Login'") }}
      </p>
      <v-form @submit.prevent="handleTwoFactorSubmit('usertfform')" data-vv-scope="tf-form">
        <v-container pa-0>
          <v-layout row wrap pa-0 v-bind:style="{ maxWidth: ($vuetify.breakpoint.xsOnly ? '290px' : 'none') }" style="margin:0 auto;">
            <v-flex xs12>
              <br/>
              <v-text-field
                  v-validate="'required|digits:6'"
                  :error-messages="vErrors.collect('usertfform.code')"
                  data-vv-name="code"
                  append-icon="mdi-key"
                  v-model="code"
                  name="code"
                  :label="$_t('Two factor code')"
                  type="text"
                  outlined
                  autocomplete="off"
                  autofocus
              ></v-text-field>

              <v-layout align-end justify-end>
                  <v-btn
                      large
                      outlined
                      class="ma-0"
                      color="primary"
                      @click="backToLogin"
                  >
                    <v-icon>
                      mdi-arrow-left
                    </v-icon>
                    {{$_t('Go back to Login')}}
                  </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    type="submit"
                    large
                    class="mr-0"
                    :disabled="submitted || !code"
                    color="primary"
                >
                  <div v-if="!submitted">
                    {{$_t('Submit')}}
                  </div>
                  <div v-else>
                    <div class="loader"></div>
                  </div>
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </div>
    <div v-else-if="twoFactor.sent && twoFactor.error" class="text-center">
      <span class="headline">{{$_t('Could not successfully send a two factor code.')}}</span>
      <br/><br/>
      <v-btn text @click="sendTwoFactorCode(twoFactor.method)" color="primary">
        <v-icon left>
          mdi-refresh
        </v-icon>
        {{$_t('Try again')}}
      </v-btn>
    </div>
    <div v-else-if="!twoFactor.sent" class="text-center">
      <div v-if="twoFactor.hasEmail && twoFactor.hasMobile && config.twoFactorAllowedMethods.includes('sms') && config.twoFactorAllowedMethods.includes('email')">
        {{$_t('Select how you would like to receive the two factor code:') }}
        <br/><br/>
        <div class="row">
          <div class="col-12">
            <v-btn color="primary" outlined v-if="twoFactor.hasMobile && config.twoFactorAllowedMethods.includes('sms')" @click="sendTwoFactorCode('sms')">
              <v-icon left>
                mdi-message-processing
              </v-icon>
              {{$_t('Send by SMS') }}
            </v-btn>
          </div>
          <div class="col-12">
            <v-btn color="primary" outlined v-if="twoFactor.hasEmail && config.twoFactorAllowedMethods.includes('email')" @click="sendTwoFactorCode('email')">
              <v-icon left>
                mdi-email
              </v-icon>
              {{$_t('Send by Email') }}
            </v-btn>
          </div>
          <div class="col-12">
            <v-btn
                outlined
                class="ma-0"
                color="primary"
                @click="backToLogin"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
              {{$_t('Go back to Login') }}
            </v-btn>
          </div>
        </div>
      </div>
      <div v-else-if="!twoFactor.hasEmail && !twoFactor.hasMobile">
        {{$_t('Could not find a valid email or mobile number associated with the account, therefore the two factor code cannot be delivered. Contact your IT team in order to complete Your account details.') }}
      </div>
      <div v-else class="text-center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
        <br/><br/>
        <span class="caption">{{$_t('Sending two factor code...') }}</span>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import config from "@/config";

export default {
  data () {
    return {
      submitted: false,
      code: null,
      config: config,
      scope: null
    }
  },
  components: {
  },
  computed: {
    ...mapState('account', ['status', 'user', 'loginFormObject', "twoFactor"])
  },
  created () {
  },
  methods: {
    ...mapActions('account', ['verifyUserTwoFactorCode', 'sendUserTwoFactorCode', 'setTwoFactorMethod']),
    ...mapActions('alert', ['error']),
    handleTwoFactorSubmit(scope) {
      this.scope = scope;
      this.submitted = true;
      this.$validator.validateAll(scope)
          .then((valid) => {
            if (valid) {
              this.handleTwoFactorRequest();
            } else {
              this.submitted = false;
            }
          });
    },
    sendTwoFactorCode(method) {
      this.scope = 'sendtfcode';
      this.setTwoFactorMethod(method);
      this.handleTwoFactorRequest();
    },
    handleTwoFactorRequest() {
      if (this.scope === 'sendtfcode') {
        this.sendUserTwoFactorCode({
          usernameOrEmail: this.loginFormObject.usernameOrEmail,
          method: this.twoFactor.method
        }).then(() => {
        });
      } else if (this.scope === 'usertfform') {
        this.verifyUserTwoFactorCode({
          usernameOrEmail: this.loginFormObject.usernameOrEmail,
          method: this.twoFactor.method,
          code: this.code
        }).then(() => {
          this.submitted = false
        })
      }
    },
    showError(message) {
      this.error(message)
    },
    onExpired() {
      window.location.reload(true)
    },
    backToLogin() {
      window.location.reload()
    },
  },
  mounted: function () {
    if (!this.twoFactor.sent) {
      if (this.twoFactor.hasEmail && (!this.twoFactor.hasMobile || !this.config.twoFactorAllowedMethods.includes('sms'))) {
        this.sendTwoFactorCode('email');
      } else if ((!this.twoFactor.hasEmail || !this.config.twoFactorAllowedMethods.includes('email')) && this.twoFactor.hasMobile) {
        this.sendTwoFactorCode('sms');
      }
    }
  },
  destroyed() {

  }
}
</script>
