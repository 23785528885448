<template>
  <v-container fluid fill-height pa-0 ma-0 :style="style" v-if="!status.loading">
    <v-layout row wrap pa-0>
      <v-flex xs12 sm8 md5 lg3 style="margin: 0 auto;">
        <v-container fluid fill-height>
          <v-card class="elevation-12 py-5 px-4" color="rgba(255,255,255,0.8)" style="width:100%">
            <p class="text-center headline">
              <img :src="config.logoFull" style="max-width:200px;" />
              <br/>
              {{$_t('Administrator login')}}
            </p>
            <!-- Login form -->
            <div class="form-wrapper">
              <UserLoginForm :is-admin-login="true" v-if="!redirectLink"/>
              <UserTwoFactorForm :link="'/admin/home'" v-if="redirectLink && config.twoFactorEnabled && !twoFactor.completed"></UserTwoFactorForm>
              <UserTermsOfServiceForm v-if="config.checkTosEnabled && twoFactor.completed && !isTosAccepted"></UserTermsOfServiceForm>
            </div>
          </v-card>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import config from '@/config';
import UserLoginForm from "@/components/Login/User/UserLoginForm";
import UserTwoFactorForm from "@/components/Login/User/UserTwoFactorForm";
import UserTermsOfServiceForm from "@/components/Login/User/UserTermsOfServiceForm";

export default {
  components: {
    UserLoginForm,
    UserTwoFactorForm,
    UserTermsOfServiceForm
  },
  data () {
    return {
      config: {'logoFull': '#', 'background': false}
    }
  },
  computed: {
    ...mapState('account', ['status', 'user', 'redirectLink', 'isTosAccepted', 'twoFactor']),
    style() {
      if (this.config.background) {
        return {
          'background-size': 'cover',
          'background-image': 'url("' + this.config.background + '")'
        }
      }
      return ''
    }
  },
  created () {
    if(this.status.loggedIn) {
      this.$router.push({ path: '/admin/home' });
    }
  },
  methods: {},
  mounted: function () {
    this.config = config
  },
  destroyed() {}
}
</script>
