import config from '@/config';

export const clientService = {
    getClient,
    postApproval
};

function getClient(id) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(config.authApiUrl+`/auth/client/`+id, requestOptions)
        .then(handleResponse);
}

function postApproval(answer) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({answer: answer})
    };

    return fetch(config.authApiUrl+`/auth/approve`, requestOptions).then(handleResponse);
}

function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                location.reload();
            }

            const error = (data && data.error) || response.statusText;

            return Promise.reject(error);
        }

        return data;
    });
}