<template>
  <v-container fluid fill-height pa-0 pt-16>
    <v-layout fill-height pa-0 v-if="loading">
      <div class="text-center mt-12" style="width:100%">
        <v-progress-circular indeterminate color="primary" size="48"></v-progress-circular>
      </div>
    </v-layout>
    <v-layout fill-height pa-0 v-else-if="!isAdmin">
      <v-row class="mt-6">
        <v-col cols="6" offset="3">
          <v-alert color="error" dark icon="mdi-cancel">
            {{ $_t('Your account does not have admin privileges assigned to it') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-layout>
    <v-layout fill-height pa-0 v-else>
      <v-container fluid :class="{'pa-0': $vuetify.breakpoint.xsOnly, 'pa-5': $vuetify.breakpoint.smAndUp}">
        <v-layout :column="$vuetify.breakpoint.smAndDown" :row="$vuetify.breakpoint.mdAndUp" wrap>
          <v-card class="pa-1" elevation="5">
            <v-card-title>
              {{$_t('User management')}}
            </v-card-title>
          <v-row>
            <v-col cols="12" v-if="users" class="pl-4">
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    v-on:keyup.enter="fetchUsers"
                    :disabled="usersLoading"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn color="primary" @click="fetchUsers" :disabled="usersLoading">{{$_t('Search')}}</v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="userTableHeaders"
                :items="users"
                class="elevation-1"
                :options.sync="options"
                :server-items-length="usersCount"
                :loading="usersLoading"
                :footer-props="{
                  'items-per-page-options': [10, 20, 50, 100]
                }"
              >
                <template v-slot:item.is_admin="{ item }">
                  <v-icon color="green" v-if="item.is_admin">mdi-check-circle</v-icon>
                  <v-icon color="red" v-else>mdi-close-circle</v-icon>
                </template>
                <template v-slot:item.is_ldap="{ item }">
                  <v-icon color="green" v-if="item.is_ldap">mdi-check-circle</v-icon>
                  <v-icon color="red" v-else>mdi-close-circle</v-icon>
                </template>
                <template v-slot:item.is_active="{ item }">
                  <v-icon color="green" v-if="item.is_active">mdi-check-circle</v-icon>
                  <v-icon color="red" v-else>mdi-close-circle</v-icon>
                </template>
                <template v-slot:item.is_rate_limitted="{ item }">
                  <div v-if="item.is_rate_limitted">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="red" v-if="item.is_rate_limitted" v-bind="attrs" v-on="on">mdi-lock</v-icon>
                      </template>
                      <span>{{$_t('Locked out since')}}&nbsp;{{item.lockout_timestamp}}</span>
                    </v-tooltip>
                    <v-btn class="ml-2" tile outlined color="primary" x-small @click="clearRateLimitByUsername(item.username)">{{$_t('Clear')}}</v-btn>
                  </div>
                  <div v-else>
                    <v-icon color="green">mdi-lock-open-variant</v-icon>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-card>
                <v-card-text class="pa-6 pt-0">
                  <p class="title-1 pt-2">
                    {{ $_t('Users are temporarily locked out when too many invalid login attempts are made on the account. If a user is locked out, a red lock will appear on their record with a button `Clear`. Clicking it will unlock that user and allow them to attempt to login again. Otherwise use the button below to clear all users from rate limiting, allowing all users to login again (keep in mind clearing all users might take some time, depending on the amount of users on the system)') }}
                  </p>
                      <v-btn color="primary" outlined @click="clearRateLimits" class="text-none">
                        {{ $_t('Clear all lockouts') }}
                      </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          </v-card>
        </v-layout>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import config from '@/config';
import {mapActions, mapState} from "vuex";

export default {
  data: function () {
    return {
      config: {},
      sections: {
        rateLimit: {
          username: null
        }
      },
      userTableHeaders: [
        {
          text: 'ID',
          align: 'start',
          value: 'user_id',
        },
        { text: 'Username', value: 'username' },
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Mobile', value: 'mobile' },
        { text: 'Active', value: 'is_active' },
        { text: 'LDAP', value: 'is_ldap' },
        { text: 'Admin', value: 'is_admin' },
        { text: 'Locked out?', value: 'is_rate_limitted', sortable: false }
      ],
      search: null,
      options: {}
    }
  },
  computed: {
    ...mapState('admin', ['loading', 'isAdmin', 'users', 'usersLoading', 'usersCount'])
  },
  watch: {
    options: {
      handler () {
        this.fetchUsers()
      },
      deep: true,
    }
  },
  created() {
  },
  methods: {
    ...mapActions({
      clearRateLimitByUsername: 'admin/clearRateLimitByUsername',
      clearRateLimits: 'admin/clearRateLimits',
      getIsAdmin: 'admin/isAdmin',
      getUserList: 'admin/getUserList',
    }),
    fetchUsers() {
      if (this.usersLoading) {
        return
      }
      if (this.search) {
        this.options.search = this.search.trim().toLowerCase();
      } else {
        if (typeof this.options.search !== 'undefined') {
          delete this.options.search
        }
      }
      this.getUserList(this.options)
    }
  },
  mounted() {
    this.config = config;
    this.getIsAdmin();
    this.fetchUsers()
  }
}
</script>
