import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import config from '@/config'

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: config.themes
    },
    icons: {
        iconfont: 'mdi'
    }
}

export default new Vuetify(opts);
