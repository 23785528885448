import {userService} from '@/services';
import router from '@/helpers/router';
import config from '@/config';
import device from '@/helpers/device';

const state = {
  status: {
    loginRequest: false,
    loggedIn: false,
    loading: true,
    hideForm: false,
    passwordTokenValid: false,
    passwordResetSuccess: false,
    twoFactor: false,
    recaptchaRequired: true
  },
  socialLogin: {
    google: false
  },
  registrationEnabled: false,
  patient: false,
  user: false,
  redirectLink: false,
  twoFactor: {
    method: null,
    sent: false,
    error: false,
    hasEmail: false,
    hasMobile: false,
    completed: false,
    enabled: false
  },
  loginFormObject: null,
  passwordCreate: null,
  passwordCreateCompleted: false,
  formLoading: false,
  isTosAccepted: false
};

const actions = {
  sendUserTwoFactorCode({dispatch, commit}, {usernameOrEmail, recaptchaToken, method}) {
    commit('sendTwoFactorRequest', method);
    commit('loading');
    userService.sendUserTwoFactorCode(usernameOrEmail, recaptchaToken, method)
      .then(
        data => {
          commit('sendTwoFactorSuccess');
          dispatch('alert/success', {message: data.data}, {root: true});
          commit('loaded');
        },
        error => {
          commit('sendTwoFactorError');
          dispatch('alert/error', error, {root: true});
          commit('loaded');
        }
      );
  },

  verifyUserTwoFactorCode({dispatch, commit, state}, {usernameOrEmail, recaptchaToken, method, code}) {
    commit('loading');
    userService.verifyUserTwoFactorCode(usernameOrEmail, recaptchaToken, method, code)
      .then(
        data => {
          commit('twoFactorCompleted');
          commit('redirectLink', data.data.redirectLink);
          if (!config.checkTosEnabled || state.isTosAccepted) {
            dispatch('alert/success', {message: "Code verified, logging in"}, {root: true});
            commit('verifyTwoFactorSuccess', data.data);
          } else {
            dispatch('alert/success', {message: "Code verified, please accept terms of service in order to continue"}, {root: true});
            commit('loaded');
          }
        },
        // eslint-disable-next-line
        error => {
          dispatch('alert/error', {message: "Invalid code"}, {root: true});
          commit('loaded');
        }
      );
  },

  sendPatientTwoFactorCode({dispatch, commit}, formData) {
    commit('sendTwoFactorRequest', formData.method);
    commit('loading');
    userService.sendPatientTwoFactorCode(formData)
      .then(
        data => {
          commit('sendTwoFactorSuccess');
          dispatch('alert/success', {message: data.data}, {root: true});
          commit('loaded');
        },
        error => {
          commit('sendTwoFactorError');
          dispatch('alert/error', error, {root: true});
          commit('loaded');
        }
      );
  },

  verifyPatientTwoFactorCode({dispatch, commit, state}, formData) {
    commit('loading');

    userService.verifyPatientTwoFactorCode(formData)
      .then(
        data => {
          commit('redirectLink', data.data.redirectLink);
          commit('twoFactorCompleted');
          if (!config.checkTosEnabled || state.isTosAccepted) {
            dispatch('alert/success', {message: "Code verified, logging in"}, {root: true});
            commit('verifyTwoFactorSuccess', data.data);
          } else {
            dispatch('alert/success', {message: "Code verified, please accept terms of service in order to continue"}, {root: true});
            commit('loaded');
          }
        },
        // eslint-disable-next-line
        error => {
          dispatch('alert/error', {message: "Invalid code"}, {root: true});
          commit('loaded');
        }
      );
  },

  loginPatient({dispatch, commit}, {dateOfBirth, lastName, phoneNumber, email, recaptchaToken, password}) {

    userService.loginPatient(dateOfBirth, lastName, phoneNumber, email, recaptchaToken, password)
      .then(
        data => {
          commit('loading');
          commit('loginSuccess', data.data);
          dispatch('alert/success', {message: "Successfully logged in"}, {root: true});
          commit('loaded');
        },
        error => {
          commit('loginFailure');
          dispatch('alert/error', error, {root: true});
        }
      );
  },

  submitPatientIdentity({dispatch, commit}, formData) {
    commit('submitPatientIdentityRequest');
    commit('formLoading');
    userService.submitPatientIdentity(formData)
      .then(
        data => {
          commit('loading');
          if ((config.simplifiedPatientLogin && !config.twoFactorEnabled && !config.checkTosEnabled) || !data.data.newDevice) {
            window.location.href = data.data.redirectLink;
          } else {
            if (!config.twoFactorEnabled) {
              commit('twoFactorCompleted');
            }
            commit('twoFactorOptions', {
              hasEmail: data.data.hasEmail,
              hasMobile: data.data.hasMobile,
              isTosAccepted: data.data.isTosAccepted
            });
            commit('loginFormObject', formData);
            dispatch('alert/success', {message: "Patient found"}, {root: true});
            commit('loaded');
          }
        },
        error => {
          commit('submitPatientIdentityFailure', error);
          dispatch('alert/error', {message: error.message}, {root: true});
          commit('loaded');
          commit('formLoaded');
        }
      );
  },

  submitUserIdentity({dispatch, commit}, {
    usernameOrEmail,
    password,
    recaptchaToken,
    rememberMe,
    isAdminLogin,
    isIframe,
    callback
  }) {
    commit('submitUserIdentityRequest');
    commit('formLoading');
    userService.submitUserIdentity(usernameOrEmail, password, recaptchaToken)
      .then(
        data => {
          commit('loading');
          if (!isIframe) { // access to localStorage not possible through iFrame
            if (rememberMe) {
              localStorage.setItem('loginForm.username', usernameOrEmail);
            } else {
              localStorage.removeItem('loginForm.username');
            }
          }

          if ((!config.twoFactorEnabled && !config.checkTosEnabled) || !data.data.newDevice) {
            if (isAdminLogin) {
              window.location.href = '/admin/home';
            } else {
              if (isIframe) {
                window.parent.postMessage({
                  action: 'auth.loginConfirm',
                  url: data.data.redirectLink
                }, '*');
              } else {
                window.location.href = data.data.redirectLink;
              }
            }
            return
          } else {
            if (!config.twoFactorEnabled) {
              commit('twoFactorCompleted');
            }
            commit('twoFactorOptions', {
              hasEmail: data.data.hasEmail,
              hasMobile: data.data.hasMobile,
              isTosAccepted: data.data.isTosAccepted
            });
            commit('loginFormObject', {
              usernameOrEmail,
              username: usernameOrEmail,
              password
            });
            dispatch('alert/success', {message: "User found"}, {root: true});
            commit('loaded');
          }
          callback(true);
        },
        error => {
          commit('submitUserIdentityFailure', error);
          dispatch('alert/error', {message: error.message}, {root: true});
          commit('loginFailure', error);
          commit('loaded');
          commit('formLoaded');
          callback(false);
        }
      );
  },

  doSocialLogin({dispatch, commit}, {type, id_token, recaptchaToken, promptCallback, link}) {
    commit('formLoading');
    if (typeof link === 'undefined') {
      link = false
    }
    userService.socialLogin(type, id_token, recaptchaToken, link)
      .then(
        data => {
          if (data.data.redirectLink) {
            commit('loading')
            commit('socialLoginSuccess', data.data);
            dispatch('alert/success', {message: "Successfully logged in"}, {root: true});
          } else if (data.data.linkUserPrompt) {
            promptCallback()
            commit('formLoaded');
          } else {
            console.error('Unexpected social login response'); // eslint-disable-line
          }
        },
        error => {
          commit('socialLoginFailure', error);
          dispatch('alert/error', error, {root: true});
          commit('formLoaded');
        }
      );
  },

  loggedInRedirect(next) {
    userService.isLoggedIn().then(
      data => {
        if (!data.data) {
          router.push({name: 'login'});
        } else {
          next();
        }
      }
    )
  },

  isLoggedIn({commit}) {
    userService.isLoggedIn()
      .then(
        data => {
          commit('isLoggedInSuccess', data.data);
        },
        // eslint-disable-next-line
        error => {
          commit('isLoggedInError');
        }
      );
  },

  logout({commit}) {
    commit('loading');
    commit('logout');
    userService.logout();
    router.push({name: 'login'});
    commit('loaded');
  },

  loading({commit}) {
    commit('loading');
  },

  loaded({commit}) {
    commit('loaded');
  },

  register({dispatch, commit}, user) {
    commit('loading');
    commit('registerRequest', user);

    userService.register(user)
      .then(
        data => {
          commit('registerSuccess', data);
          dispatch('alert/success', {message: "User created, logging in..."}, {root: true});
          window.location.href = data.data;
        },
        error => {
          commit('registerFailure', error);
          dispatch('alert/error', error, {root: true});
          commit('loaded');
        }
      );
  },
  sendPasswordResetRequest({dispatch, commit}, email) {
    commit('loading');

    userService.passwordResetRequest(email)
      .then(
        // eslint-disable-next-line
        data => {
          dispatch('alert/success', {message: "Password reset request received. Check your inbox"}, {root: true});
          commit('sendPasswordResetRequestSuccess');
          commit('loaded');
        },
        error => {
          dispatch('alert/error', error, {root: true});
          commit('loaded');
        }
      );
  },
  sendPatientPasswordResetRequest({dispatch, commit}, formData) {
    commit('loading');

    userService.patientPasswordResetRequest(formData)
      .then(
        // eslint-disable-next-line
        data => {
          dispatch('alert/success', {message: "If patient record was found, the password reset instructions will be sent to the email inbox belonging to it"}, {root: true});
          commit('sendPasswordResetRequestSuccess');
          commit('loaded');
        },
        error => {
          dispatch('alert/error', error, {root: true});
          commit('loaded');
        }
      );
  },
  verifyResetPasswordTokenRequest({dispatch, commit}, token) {
    commit('loading');
    userService.verifyResetPasswordTokenRequest(token)
      .then(
        data => {
          if (data.data) {
            dispatch('alert/success', {message: "Enter your new password"}, {root: true});
            commit('verifyResetPasswordTokenSuccess');
            commit('loaded');
          } else {
            dispatch('alert/error', {message: "Password reset token has expired, request a new one by entering your email"}, {root: true});
            commit('verifyResetPasswordTokenFailure');
            commit('loaded');
          }
        },
        // eslint-disable-next-line
        error => {
          dispatch('alert/error', {message: "The password reset token has already been used or is invalid"}, {root: true});
          commit('verifyResetPasswordTokenFailure');
          commit('loaded');
        }
      );
  },
  changePasswordRequest({dispatch, commit}, {password, token, callback}) {
    commit('loading');
    userService.changePasswordRequest(password, token)
      .then(
        // eslint-disable-next-line
        data => {
          dispatch('alert/success', {message: "Password has been changed"}, {root: true});
          commit('changePasswordRequestSuccess');
          commit('loaded');
          if (config.passwordResetRedirect !== undefined) {
            setTimeout(() => {
              window.location.href = config.passwordResetRedirect
            }, 3000);
          }
          callback(true)
        },
        // eslint-disable-next-line
        error => {
          dispatch('alert/error', {message: error.message}, {root: true});
          commit('loaded');
          callback(false)
        }
      );
  },

  isGoogleLoginEnabled({commit}, callback) {
    userService.isGoogleLoginEnabled()
      .then(
        data => {
          commit('isGoogleLoginEnabledSuccess', data.data);
          callback(data.data)
        },
        error => {
          commit('isGoogleLoginEnabledFailure', error);
        }
      );
  },

  isRecaptchaIPWhitelisted({commit}) {
    userService.isRecaptchaIPWhitelisted()
      .then(
        data => {
          commit('isRecaptchaIPWhitelistedSuccess', data.data);
        },
        // eslint-disable-next-line
        error => {
          commit('isRecaptchaIPWhitelistedError');
        }
      );
  },
  clearPatient({commit}) {
    commit('clearPatient');
  },
  loginRequest({commit}) {
    commit('loginRequest');
  },
  setTwoFactorMethod({commit}, method) {
    commit('setTwoFactorMethod', method);
  },
  acceptTos({commit}, formData) {
    userService.acceptTos(formData).then(
      // eslint-disable-next-line
      data => {
        commit('verifyTwoFactorSuccess')
      },
      error => {
        // eslint-disable-next-line
        console.error(error)
      }
    )
  },
  sendPasswordCreateRequest({dispatch, commit}, formData) {
    commit('formLoading');
    userService.passwordCreateRequest(formData)
      .then(
        // eslint-disable-next-line
        data => {
          commit('sendPasswordCreateRequestSuccess', formData);
          commit('formLoaded');
        },
        error => {
          commit('sendPasswordCreateFailure');
          dispatch('alert/error', error, {root: true});
          commit('formLoaded');
        }
      );
  },
  verifyCreatePasswordRequest({dispatch, commit}, formData) {
    commit('formLoading');
    userService.verifyCreatePasswordRequest(formData)
      .then(
        data => {
          if (data.data) {
            let redirectLink = config.passwordResetRedirect
            if (config.passwordResetRedirect !== undefined) {
              redirectLink = config.passwordResetRedirect
            }

            if (config.checkTosEnabled && !data.data.isTosAccepted) {
              commit('loginFormObject', formData);
              commit('passwordCreateCompleted', true)
              commit('redirectLink', redirectLink)
            } else {
              commit('loading');
              dispatch('alert/success', {message: "Password has been created, You will now be redirected to the log in page..."}, {root: true})
              setTimeout(() => {
                window.location.href = redirectLink
              }, 3000);
            }
          } else {
            commit('formLoaded');
            dispatch('alert/error', {message: "Failed to create a password, make sure the code entered is correct"}, {root: true});
          }
        },
        // eslint-disable-next-line
        error => {
          commit('formLoaded');
          dispatch('alert/error', {message: "Failed to create a password, make sure the code entered is correct"}, {root: true});
        }
      );
  },
  setPasswordCreateCompleted({commit}, value) {
    commit('passwordCreateCompleted', value)
  },
  resetPasswordTokenValidity({commit}) {
    commit('resetPasswordTokenValidity')
  }
};

const mutations = {
  loading(state) {
    state.status.loading = true;
  },
  loginSuccess(state, url) {
    state.status.loggedIn = true;
    window.location.href = url;
  },
  socialLoginSuccess(state, data) {
    state.status.loggedIn = true;
    window.location.href = data.redirectLink;
  },
  loginFailure(state) {
    state.status.loggedIn = false;
    state.status.loginRequest = false;
  },
  socialLoginFailure(state) {
    state.status.loggedIn = false;
  },
  logout(state) {
    state.status.loggedIn = false;
  },
  registerRequest(state) {
    state.status.registering = true;
  },
  isLoggedInSuccess(state, data) {
    state.status.loggedIn = data;
  },
  isLoggedInError(state) {
    state.status.loggedIn = false;
  },
  verifyResetPasswordTokenSuccess(state) {
    state.status.passwordTokenValid = true;
  },
  verifyResetPasswordTokenFailure(state) {
    state.status.passwordTokenValid = false;
  },
  resetPasswordTokenValidity(state) {
    state.status.passwordTokenValid = false;
    state.status.passwordResetSuccess = false;
  },
  loaded(state) {
    state.status.loading = false;
  },
  isGoogleLoginEnabledSuccess(state, data) {
    state.socialLogin.google = data;
  },
  isGoogleLoginEnabledFailure(state) {
    state.socialLogin.google = false;
  },
  isRegistrationEnabledSuccess(state, data) {
    state.registrationEnabled = data;
  },
  isRegistrationEnabledFailure(state) {
    state.registrationEnabled = false;
  },
  submitPatientIdentityRequest(state) {
    state.patient = false;
  },
  submitPatientIdentityFailure(state) {
    state.patient = false;
  },
  submitUserIdentityRequest(state) {
    state.user = false;
  },
  submitUserIdentityFailure(state) {
    state.user = false;
  },
  clearPatient(state) {
    state.patient = false;
  },
  sendTwoFactorRequest(state, method) {
    state.twoFactor.method = method;
  },
  sendTwoFactorSuccess(state) {
    state.twoFactor.sent = true;
    state.twoFactor.error = false;
  },
  sendTwoFactorError(state) {
    state.twoFactor.sent = true;
    state.twoFactor.error = true;
  },
  verifyTwoFactorSuccess(state, data = null) {
    if (data.trustDevice) {
      device.generateDeviceCookie(data.username, data.deviceCookieValue, data.deviceCookieExpiryTimestamp);
      device.generateDeviceCookie(data.email, data.deviceCookieValue, data.deviceCookieExpiryTimestamp);
    }
    state.status.loggedIn = true;
    window.location.href = state.redirectLink;
  },
  sendPasswordResetRequestSuccess(state) {
    state.status.hideForm = true;
  },
  changePasswordRequestSuccess(state) {
    state.status.passwordResetSuccess = true;
    state.status.hideForm = true;
  },
  loginFormObject(state, data) {
    state.loginFormObject = data;
  },
  loginRequest(state) {
    state.status.loginRequest = true;
  },
  twoFactorOptions(state, options) {
    state.twoFactor.enabled = true;
    state.twoFactor.hasEmail = options.hasEmail;
    state.twoFactor.hasMobile = options.hasMobile;
    state.isTosAccepted = options.isTosAccepted;
  },
  setTwoFactorMethod(state, method) {
    state.twoFactor.method = method;
  },
  isRecaptchaIPWhitelistedSuccess(state, data) {
    state.status.recaptchaRequired = data;
  },
  isRecaptchaIPWhitelistedError(state) {
    state.status.recaptchaRequired = true;
  },
  redirectLink(state, redirectLink) {
    state.redirectLink = redirectLink;
  },
  twoFactorCompleted(state) {
    state.twoFactor.completed = true;
  },
  sendPasswordCreateRequestSuccess(state, data) {
    state.passwordCreate = data
  },
  sendPasswordCreateFailure(state) {
    state.passwordCreate = null
  },
  formLoading(state) {
    state.formLoading = true
  },
  formLoaded(state) {
    state.formLoading = false
  },
  setFormLoading(state, value) {
    state.formLoading = value
  },
  passwordCreateCompleted(state, value) {
    state.passwordCreateCompleted = value
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
};
